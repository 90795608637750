import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { setAdminAccountTab } from "../../redux/reducers/navigationTabAccount";
import { RootState } from "../../redux/store";

import style from "./Fournissseurs.module.css";
import translation from "../../locales/fr-translation.json";

import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import CustomPaginationActionsTable from '../../components/CustomPaginationActionsTableAdmin/CustomPaginationActionsTableAdmin';
import { User } from '../../api/interfaces/user.interface';
import { setFormClose, setFormOpen } from '../../redux/reducers/formDisplay';
import { useDeleteAdminMutation } from '../../redux/apiSlice';
import { setErrorSnackBar, setSuccessSnackBar } from '../../redux/reducers/snackbar';
import LodingPage from '../../components/LodingPage/LodingPage';


const Administrateurs: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setAdminAccountTab());
  }, [])

  const accountTabtype = useSelector((state: RootState) => state.navigationAccountTab.accountType)
  const [open, setOpen] = useState(false);
  const [data, setData] = useState<User[]>([{uid: ""}]);
  const isFormOpen = useSelector((state: RootState) => state.form.isFormOpen);
  const accounttype = useSelector((state: any) => state.accountType)

  const [deleteAdmin, result] = useDeleteAdminMutation();

  if (result.isError) {
    const errorData = JSON.stringify(result.error);
    const errorDataJson = JSON.parse(errorData);
    dispatch(setErrorSnackBar(errorDataJson.data.message));
  } else if (result.isSuccess) {
    dispatch(setSuccessSnackBar(translation.SuccessMessages.deleteAdmin));
  }

  
  return (
    <div className={style.mainContainer}>
      {
        result.isLoading ?
        <LodingPage /> :
        <></>
      }
    <div className={style.headerContainer}>
        <p className={style.title}>{translation.Administrateurs.title}</p>
        <Button variant='contained' startIcon={<AddIcon />} className={style.button} onClick={() => isFormOpen? dispatch(setFormClose()) : dispatch(setFormOpen())}>
          {translation.Administrateurs.buttonText}
        </Button>
    </div>
    <CustomPaginationActionsTable open={open} setOpen={setOpen} isAdmin={false} deleteUser={deleteAdmin}/>
  </div>
  )
}

export default Administrateurs