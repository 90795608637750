import React, { useEffect, useState } from 'react';
import data from "../../tools/historyData";
import style from "./Historique.module.css";
import translation from "../../locales/fr-translation.json";
import { RootState } from '../../redux/store';
import { useSelector , useDispatch } from "react-redux";
import  axios  from "axios";
import Button from '@mui/material/Button';
import DownloadIcon from '@mui/icons-material/Download';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Stack from '@mui/material/Stack';
import NativeSelect from '@mui/material/NativeSelect';
import { useGetOrdersHistoryQuery, useGetAllDistributorsQuery } from '../../redux/apiSlice';
import { setDistributorUid } from '../../redux/reducers/distributorUid';
import LodingPage from '../../components/LodingPage/LodingPage';
import { setErrorSnackBar } from '../../redux/reducers/snackbar';
import Typography from '@mui/material/Typography';

const { REACT_APP_BACK_URI } = process.env

const Historique: React.FC = () => {
  const dispatch = useDispatch();
  const [downloadLoding, setDownloadLoding] = useState(false);
  let getCSV = (idToken: string | null, oid: string | null, accountType: String, from: String, to: String) => {
    try {
      setDownloadLoding(true);
      axios({
        method: 'get',
        url: `${REACT_APP_BACK_URI}/${accountType}/history/summary`,
        responseType: 'blob',
        headers: {
          'Access-Control-Allow-Origin': true,
          'Authorization': `Bearer ${idToken}`
        },
        params: {from: from, to: to, uid: oid}
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `Historique des commandes ${new Date(to.toString()).getFullYear()} - ${new Date(to.toString()).getMonth() + 1 }.csv`);
          document.body.appendChild(link);
          link.click();
          setDownloadLoding(false);
        })
        .catch((error) => {
          dispatch(setErrorSnackBar("Une erreur s'est produite lors du téléchargement de votre fichier."));
          setDownloadLoding(false);
        })
    } catch (error) {

    }
  } 

  const monthFR = ["Janvier","Février","Mars","April","Mai","Juin","Juillet","Août","Septembre","Octobre","Novembre","Décembre"];
  const [year, setYear] = useState(data[0].year);
  const distributorUid = useSelector((state: RootState) => state.distributorUid.uid);
  const accounttype = useSelector((state: RootState) => state.account.accountType);
  const DistrbutorName = useSelector((state: RootState) => state.account.distributorName);
  const allDistributorsInfo = useGetAllDistributorsQuery('');
  const { data: historyData, isLoading, isError, error, isSuccess, refetch } = useGetOrdersHistoryQuery({ accountType: accounttype ? "distributor" : "admin/orders", uid: (distributorUid !== "AllDist" ? (`?uid=${encodeURIComponent(distributorUid)}`) : '')},);

  
  useEffect(() => {
    if (isSuccess && historyData != null) {
    setYear(historyData[historyData.length -1].year);
    }

  }, [isSuccess, distributorUid])


  const handleChange = (event: SelectChangeEvent) => {
    setYear(event.target.value as string);
  };

  const handleChangeDistributor = (event: SelectChangeEvent) => {
    dispatch(setDistributorUid(event.target.value as string));
  };

  if (isError) {
    const errorData = JSON.stringify(error);
    const errorDataJson = JSON.parse(errorData);
    dispatch(setErrorSnackBar(errorDataJson.error ? errorDataJson.error : errorDataJson.data.message));
  }


  return (
        <div className={style.mainContainer}>
          {downloadLoding ? <LodingPage /> : <></>}
          <>
            <div className={style.headerContainer}>          
                <p className={style.title}>{translation.Historique.title} {accounttype ? ` : ${DistrbutorName}` : ''}</p>
                <Stack direction="row" className={style.TitleButtonGroup} spacing={3}>
                {accounttype ? <></> :
                    <Box sx={{ minWidth: 250 }}>
                        <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-standard-label">Fournisseurs</InputLabel>
                            <Select
                              labelId="demo-simple-select-standard-label"
                              id="demo-simple-select-standard-label"
                              value={distributorUid}
                              defaultValue={"AllDist"}
                              onChange={handleChangeDistributor}
                              label="demo-simple-select-standard-label"
                            > 
                                <MenuItem value={"AllDist"}>Tous les fournisseurs</MenuItem>
                                {allDistributorsInfo.isSuccess && allDistributorsInfo.data != null ?
                                (allDistributorsInfo.data.length != 0 ? allDistributorsInfo.data.map((distributor : any) => {return <MenuItem value={distributor.fulfillment_id} key={distributor.fulfillment_id}>{distributor.full_name}</MenuItem>}): <MenuItem value={"AllDist"}>Il n'y a pas de fournisseurs</MenuItem>)
                                : <>{allDistributorsInfo.data == null ? <></> : <MenuItem value={"AllDist"}>Téléchargement de données</MenuItem>}</>}
                            </Select>
                        </FormControl>
                    </Box>}
                    <Box className={style.selectBox}>
                      <FormControl size="small">
                        <InputLabel id="demo-simple-select-label" className={style.selectLabel}>{translation.Historique.year}</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={year}
                          label="Year"
                          onChange={handleChange}
                        >
                          {isSuccess && historyData != null ? historyData.map((year: any) => {
                            return <MenuItem key={year.year} value={year.year}>{year.year}</MenuItem>
                          }) : <MenuItem key="null" value={data[0].year}>Pas de données</MenuItem>}
                        </Select>
                      </FormControl>
                    </Box>
                </Stack>
            </div>

            {   isSuccess && historyData != null ?
                historyData.map( (years: any) => {
                  if (years.year == year) {
                  return <TableContainer component={Paper} className={style.table} key={years.year}>
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                          <TableHead>
                              <TableRow>
                              <TableCell colSpan={3} align="left" key={years.year} className={style.year} >{years.year}</TableCell>
                              </TableRow>
                          </TableHead>
                          <TableBody>
                                {
                                years.data.map((month: any) => {
                                  let date = new Date(month.to).getMonth()
                                  return <TableRow
                                  key={years.year+month.to}
                                  className={style.monthContainer}
                                  sx={{ '&:last-child td, &:last-child th': { border: 0 }  }}
                                >
                                    <TableCell align="left" className={style.monthText}>{ monthFR[date] }</TableCell>
                                    <TableCell align="left" className={style.commandsText}>{translation.Historique.commands}: {month.count}</TableCell>
                                    <TableCell align="right">
                                        <Button variant='outlined' startIcon={<DownloadIcon />} className={style.button} onClick={() => { getCSV(localStorage.getItem('profile') ,(distributorUid !== "AllDist" ? (`${distributorUid}`) : null), accounttype ? "distributor" : "admin/orders", month.from , month.to) }}>{translation.Historique.export}</Button>
                                    </TableCell>
                                </TableRow>
                                })
                              }
                          </TableBody>
                      </Table>
                  </TableContainer>; 
                } else {
                  return null;
                }

                } ): <>{historyData == null ? <Typography variant="h5" component="div" gutterBottom>Pas de données </Typography> :<LodingPage />}</>
            }
          </>
        </div>
  )
}

export default Historique