import React, { useEffect } from 'react';
import style from './CancelDialog.module.css';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { handleOpen, handleCloseDialog } from "../../redux/reducers/dialog";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from "../../redux/store";
import Button from '@mui/material/Button';
import { useCancelOrderAdminMutation } from '../../redux/apiSlice';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import { setErrorSnackBar, setSuccessSnackBar } from '../../redux/reducers/snackbar';
import translation from "../../locales/fr-translation.json";
import { setPage, setSuccess } from '../../redux/reducers/fetchedOrders';

const CancelDialog: React.FC = () => {
    const dispatch = useDispatch();
    const openDialog = useSelector((state: RootState) => state.dialog.isOpen);
    const handleDialogClose = () => dispatch(handleCloseDialog());
    const order = useSelector((state: RootState) => state.dialog.order);
    const accType = useSelector((state: RootState) => state.dialog.accountType);
    const [cancelOrder, resultCancelOrder] = useCancelOrderAdminMutation();
    const [quentity, setQuentity] = React.useState(0);
    let itemsArray: any[] = [];

    const handleCancel = () => {
        for (let item in itemsArray) {
            if (itemsArray[item].quantity == 0) {
                itemsArray.splice(parseInt( item, 10 ), 1);
            }
        }
        cancelOrder({oid: order.oid.oid, accountType: accType, body: {
            "lineitems": itemsArray
          }})
          console.log("canceled order: ", itemsArray)

    }

    useEffect(() => {
      return () => {
        console.log('Cancel Dialog removed');
      }
    }, [])
    
    

    if (resultCancelOrder.isSuccess) {
      dispatch(setSuccess());
      dispatch(setSuccessSnackBar(translation.Commandes.canceledSuccessMessage));
      handleDialogClose();

  
    } else if (resultCancelOrder.isError) {
        const errorData = JSON.stringify(resultCancelOrder.error);
        const errorDataJson = JSON.parse(errorData);
        dispatch(setErrorSnackBar(errorDataJson.error ? errorDataJson.error : errorDataJson.data.message));
        dispatch(handleCloseDialog());
        
    }
    

    const handleQuantityChange = (_event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, _lid: any) => {
        // setAge(event.target.value as string);
        for (let item in itemsArray) {
            if (itemsArray[item].lid == _lid) {
                itemsArray[item].quantity = parseInt( _event.target.value, 10 )
                console.log("array: ", itemsArray);
            }
        }
      };

  return (
        <Dialog
                open={openDialog}
                onClose={handleDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle color="error" id="alert-dialog-title">
                  {"Annulation des commandes"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                  Quelles commandes voulez-vous annuler ?
                  </DialogContentText>
                </DialogContent>
                    <List>
                        {order.oid ? order.oid.products.map((item: any) => (
                            <ListItem>
                                <>{itemsArray.push({lid: item.lid, quantity: item.quantity})}</>
                                <img src={item.pictureUrl} alt="product image" className={style.productImage}/>
                                <ListItemText primary={item.description} className={style.itemText}/>
                                <TextField id="outlined-basic" label="Quantity" variant="outlined" defaultValue={item.quantity} type="number" className={style.itemQuantity} onChange={(event) => handleQuantityChange(event, item.lid)}/>
                            </ListItem>
                        )) : <></>}
                    </List>
                <DialogActions>
                  <Button color="error" onClick={handleDialogClose}>Quitter</Button>
                  <Button onClick={handleCancel} autoFocus>
                    Confirmer
                  </Button>
                </DialogActions>
        </Dialog>
  )
}

export default CancelDialog