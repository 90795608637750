import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const { REACT_APP_BACK_URI } = process.env

export const apiSlice = createApi({
    reducerPath: 'api',
    tagTypes: ["Admins", "Distributors", "Orders"],
    baseQuery: fetchBaseQuery({ 
        baseUrl: REACT_APP_BACK_URI,
        // refetchOnMountOrArgChange: true,
        prepareHeaders: (headers) => {
            headers.set('authorization', `Bearer ${localStorage.getItem('profile')}`);
            return headers;
        }
     }),
    endpoints: builder => ({
        getAdmins: builder.query({
            query: ({type, number, page}) => `/admin/accounts/${type}?number=${number}&page=${page}`,
            providesTags: ["Admins"]
        }),
        deleteAdmin: builder.mutation({
            query: (uid) => ({
                url: `/admin/accounts/admin/${uid}`,
                method: 'DELETE',

            }),
            invalidatesTags: ['Admins'],
        }),
        addAdmin: builder.mutation({
            query: (admin) => ({
                url: `/admin/accounts/admins`,
                method: 'POST',
                body: admin,
            }),
            invalidatesTags: ['Admins'],
        }),
        updateAdmin: builder.mutation({
            query: ({uid, admin}) => ({
                url: `/admin/accounts/admin/${uid}`,
                method: 'PUT',
                body: admin,
            }),
            invalidatesTags: ['Admins'],
        }),
        getAdminById: builder.query({
            query: (uid) => `/admin/accounts/admin/${uid}`
        }),
        getDistributors: builder.query({
            query: ({type, number, page}) => `/admin/accounts/${type}?number=${number}&page=${page}`,
            providesTags: ["Distributors"]
        }),
        deleteDistributor: builder.mutation({
            query: (uid) => ({
                url: `/admin/accounts/distributor/${uid}`,
                method: 'DELETE',
            }),
            invalidatesTags: ["Distributors"],
        }),
        addDistributor: builder.mutation({
            query: (distributor) => ({
                url: `/admin/accounts/distributors`,
                method: 'POST',
                body: distributor,
            }),
            invalidatesTags: ["Distributors"],
        }),
        updateDistributor : builder.mutation({
            query: ({uid, distributor}) => ({
                url: `/admin/accounts/distributor/${uid}`,
                method: 'PUT',
                body: distributor,
            }),
            invalidatesTags: ["Distributors"],
        }),
        getDistributorById: builder.query({
            query: (uid) => `/admin/accounts/distributor/${uid}`
        }),
        getShopifyFullfillments: builder.query({
            query: (uid) => `/admin/accounts/fulfillments?fid=${encodeURIComponent(uid)}`
        }),
        getAddShopifyFullfillments: builder.query({
            query: () => `/admin/accounts/fulfillments`
        }),
        getAllOrders: builder.query({
            query: ({page, accountType, uid}) => `/${accountType}/orders?page=${page}&${uid}`,
            providesTags: ["Orders"],
            invalidatesTags: ["Orders"],
        }),
        cancelOrderAdmin: builder.mutation({
            query: ({oid, accountType, body}) => ({
                url: `/${accountType}/orders/${oid}?status=DECLINED`,
                method: 'PATCH', 
                body: body
            }),
            invalidatesTags: ["Declined_Orders", "Orders", "Received_Orders", "Accepted_Orders"]
        }),
        accepteOrder: builder.mutation({
            query: ({oid}) => ({
                url: `/distributor/orders/${oid}?status=ACCEPTED`,
                method: 'PATCH'
            }),
            invalidatesTags: ["Accepted_Orders"]
        }),
        shipOrder: builder.mutation({
            query: ({oid}) => ({
                url: `/distributor/orders/${oid}?status=SHIPPED`,
                method: 'PATCH'
            }),
            invalidatesTags: ["Shipped_Orders"]
        }),
        getAllDeclinedOrders: builder.query({
            query: ({page, accountType, uid}) => `/${accountType}/orders?page=${page}&type=DECLINED${uid}`,
            providesTags: ["Declined_Orders"]
        }),
        getAllReceivedOrders: builder.query({
            query: ({page, accountType, uid}) => `/${accountType}/orders?page=${page}&type=RECEIVED${uid}`,
            providesTags: ["Received_Orders"]
        }),
        getAllAcceptedOrders: builder.query({
            query: ({page, accountType, uid}) => `/${accountType}/orders?page=${page}&type=ACCEPTED${uid}`,
            providesTags: ["Accepted_Orders"]
        }),
        getAllShippedOrders: builder.query({
            query: ({page, accountType, uid}) => `/${accountType}/orders?page=${page}&type=SHIPPED${uid}`,
            providesTags: ["Shipped_Orders"]
        }),
        getAllDistributors: builder.query({
            query: () => `/admin/orders/distributors`,
            providesTags: ["AllDistributorsInfo"]
        }),
        getMyDistributor: builder.query({
            query: () => `/distributor/account`,
            providesTags: ["MyDistrbutor"]
        }),
        updateMyDistributor: builder.mutation({
            query: ({distributor}) => ({
                url: `/distributor/account`,
                method: 'PUT',
                body: distributor,
            }),
            invalidatesTags: ["MyDistrbutor"]
        }),
        getMyAdmin: builder.query({
            query: () => `/admin/account`,
            providesTags: ["MyAdmin"]
        }),
        updateMyAdmin: builder.mutation({
            query: ({distributor}) => ({
                url: `/admin/account`,
                method: 'PUT',
                body: distributor,
            }),
            invalidatesTags: ["MyAdmin"]
        }),
        getOrdersHistory: builder.query({
            query: ({accountType, uid}) => `/${accountType}/history/${uid}`,
            providesTags: ["OrdersHistory"]
        })
    })
})

export const { useGetAdminsQuery,
               useDeleteAdminMutation,
               useAddAdminMutation,
               useUpdateAdminMutation,
               useGetAdminByIdQuery,
               useGetDistributorsQuery,
               useDeleteDistributorMutation,
               useAddDistributorMutation,
               useUpdateDistributorMutation,
               useGetDistributorByIdQuery,
               useGetShopifyFullfillmentsQuery,
               useGetAddShopifyFullfillmentsQuery,
               useGetAllOrdersQuery,
               useCancelOrderAdminMutation,
               useAccepteOrderMutation,
               useShipOrderMutation,
               useGetAllDeclinedOrdersQuery,
               useGetAllReceivedOrdersQuery,
               useGetAllAcceptedOrdersQuery,
               useGetAllShippedOrdersQuery,
               useGetAllDistributorsQuery,
               useGetMyDistributorQuery,
               useUpdateMyDistributorMutation,
               useGetMyAdminQuery,
               useUpdateMyAdminMutation,
               useGetOrdersHistoryQuery
            } = apiSlice







            // getShopifyFullfillments: builder.query({
            //     async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
            //         const distributorData = await fetchWithBQ(`/admin/accounts/distributor/${_arg}`);
            //         console.log('API _arg', _arg);
            //         if (distributorData.error) throw distributorData.error; 
            //         const shopifyId = distributorData.data.fulfillmentIdShopify;
            //         console.log('API shopifyId', encodeURIComponent(shopifyId));
            //         const resultData = await fetchWithBQ(`/admin/accounts/fulfillments?fid=${encodeURIComponent(shopifyId)}`);
            //         if (resultData.error) throw resultData.error;
            //         console.log('API result', resultData);
            //         if (!resultData.error) {
            //             return resultData.data;
            //         }
            //         return resultData
            //             ? { data: resultData}
            //             : { error: resultData.error}
            //     },
            // }),