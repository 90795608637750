import React from 'react';
import SharedLogoImage from '../Authentication/SharedLogoImage';
import style from './PageNotFound.module.css';

const PageNotFound: React.FC = () => {
  return (
    <div className={style.mainContainer}>
        <SharedLogoImage />
        <div className={style.container}>
            <p className={style.fourOfour}>404</p>
            <p className={style.text}>La Page Que Vous Recherchez Semble Introuvable</p>
        </div>
    </div>
  )
}

export default PageNotFound
