import React, { useEffect, useState } from 'react';
import style from "./InfinitScroll.module.css";
import translate from "../../locales/fr-translation.json";
import InfiniteScroll from 'react-infinite-scroll-component';
import Commande from '../Commande/Commande';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { setPage } from '../../redux/reducers/fetchedOrders';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

interface Props {
  count: number
}


const InfinitScroll: React.FC<Props> = ({count}) => {

    const dispatch = useDispatch();
    const data = useSelector((state: RootState) => state.fetchedOrders.orders);
    const page = useSelector((state: RootState) => state.fetchedOrders.page);
    return (
        
        <InfiniteScroll
            dataLength={data.length}
            className={style.gridLayout}
            next={() => {dispatch(setPage(page +1))}}
            hasMore={(data.length < count)}
            loader={
              <Box sx={{ display: 'flex', alignItems: 'centrer', justifyContent: 'center' }} className={style.seeItAll}>
                <CircularProgress />
              </Box>
            }
            endMessage={
                <div style={{ textAlign: "center" }} className={style.seeItAll}>
                  <b>{translate.Commandes.noMoreData}</b>
                </div>
              }
        >
        {
            data.map((order: any) => (
                <Commande order={order} key={order.title + order.oid} />
            ))
        }
        </InfiniteScroll>
  )
}

export default InfinitScroll