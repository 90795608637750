import * as React from 'react';
import translation from '../../locales/fr-translation.json';
import style from "./CustomPaginationActionsTableDistributor.module.css";

import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Dialog from '@mui/material/Dialog';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Stack from '@mui/material/Stack';
import AddDistributorForm from '../AddDistributorForm/AddDistributorForm';
import AddAdministrateurForm from '../AddAdministrateurForm/AddAdministrateurForm';
import { NavigationAccountType } from "../../redux/reducers/navigationTabAccount";

import { RootState } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { User } from '../../api/interfaces/user.interface';
import { setFormClose, setEditFormClose, setEditFormOpen } from '../../redux/reducers/formDisplay';
import CircularProgress from '@mui/material/CircularProgress';
import UpdatingAdministrateurForm from '../UpdatingAdministrateurForm/UpdatingAdministrateurForm';
import { useGetAdminsQuery } from '../../redux/apiSlice';
import { useGetDistributorsQuery } from '../../redux/apiSlice';
import { AdminPanelSettings } from '@mui/icons-material';
import UpdatingDistributorForm from '../UpdatingDistributorForm/UpdatingDistributorForm';
import { setErrorSnackBar } from "../../redux/reducers/snackbar";
import LodingPage from '../LodingPage/LodingPage';


interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number,
  ) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };


  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
    </Box>
  );
}


export interface SimpleDialogProps {
    open: boolean;
    isDistributor: NavigationAccountType;
    onClose: (value: string) => void;
  }
  
  function SimpleDialog(props: SimpleDialogProps) {
    const { onClose, open, isDistributor } = props;
    const isFormOpen = useSelector((state: RootState) => state.form.isFormOpen);
    const isEditFormOpen = useSelector((state: RootState) => state.form.isEditFormOpen);
  
    const handleClose = () => {
      onClose("");
    };
  
    const handleListItemClick = (value: string) => {
      onClose(value);
    };
  
    return (
      <div>
          <Dialog onClose={handleClose} open={isFormOpen} maxWidth="md" >
              {isDistributor ? <AddDistributorForm /> : <AddAdministrateurForm/>}
          </Dialog>
          <Dialog onClose={handleClose} open={isEditFormOpen} maxWidth="md" >
              {isDistributor ? <UpdatingDistributorForm /> : <UpdatingAdministrateurForm />}
          </Dialog>
      </div>
    );
  }


  interface Props {
      open: boolean,
      isAdmin: boolean,
      deleteUser: (uid: string) => void,
      setOpen: (arg0: boolean)=> void

  }

  // ----------------------------------------------------- Start of the Component----------------------------------------------------------------
  const CustomPaginationActionsTable: React.FC<Props> = ({open, setOpen, isAdmin, deleteUser}) => {
  const accountTabtype = useSelector((state: RootState) => state.navigationAccountTab.accountType);
  const isFormOpen = useSelector((state: RootState) => state.form.isFormOpen);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const dispatch = useDispatch();


  // ----------------------------------------------------- Start of Pagination ----------------------------------------------------------------

  const [page, setPage] = React.useState(0);
  let count = 0;
  const [pageSize, setPageSize] = React.useState(25);
  let data: User[] = [{uid: ''}];

    const { data: fetchedData, isLoading, isSuccess, isError, error } = useGetDistributorsQuery({type: "distributors", number: pageSize, page: page + 1});

    if (isSuccess) {
      if (fetchedData == null) {

      } else {
        count = fetchedData.count;
        data = fetchedData.payload;
      }
    }

    if (isError) {
      const errorData = JSON.stringify(error);
      const errorDataJson = JSON.parse(errorData);
      dispatch(setErrorSnackBar(translation.ErrorMessages.getDistributor));
    }




  const handleClose = (value: string) => {
    dispatch(setFormClose());
    dispatch(setEditFormClose());
  };

  const handleEditUser = (uid: string) => {
    dispatch(setEditFormOpen(uid));
    
  }

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =  page > 0 ? Math.max(0, (1 + page) * rowsPerPage - count) : 0;

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <TableContainer component={Paper} className={style.tableContainer}>
      {isLoading ? <LodingPage /> :
      <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
            <TableHead>
                <TableRow key='header'>
                    <TableCell>{translation.CustomPaginationActionsTable.tableHeaderId}</TableCell>
                    <TableCell>{accountTabtype ? translation.CustomPaginationActionsTable.tableHeaderDistributor : translation.CustomPaginationActionsTable.tableHeaderFirstName}</TableCell>
                    <TableCell>{accountTabtype ? translation.CustomPaginationActionsTable.tableHeaderPhone : translation.CustomPaginationActionsTable.tableHeaderLastName}</TableCell>
                    <TableCell>{translation.CustomPaginationActionsTable.tableHeaderEmail}</TableCell>
                    <TableCell>{translation.CustomPaginationActionsTable.tableHeaderActions}</TableCell>
                </TableRow>
            </TableHead>
        <TableBody>
          {data[0].uid != '' ? (data
          ).map((row) => (
            <TableRow key={row.uid + 'uid-'}>
              <TableCell component="th" scope="row">
                {row.uid}
              </TableCell>
              <TableCell style={{ width: 160 }}>
                {row.companyName || row.firstName}
              </TableCell>
              <TableCell style={{ width: 160 }}>
                {row.phoneNumber || row.lastName}
              </TableCell>
              <TableCell style={{ width: 160 }}>
                {row.email}
              </TableCell>
              <TableCell style={{ width: 160 }}>
                  <Stack direction="row" >
                      <IconButton onClick={() => deleteUser(row.uid)}>
                          <DeleteIcon />
                      </IconButton>
                      <IconButton onClick={() => handleEditUser(row.uid)}>
                          <EditIcon />
                      </IconButton>
                  </Stack>
              </TableCell>
            </TableRow>
          )) : <TableRow style={{ height: 53, textAlign: "center"  }} key='noUser'><TableCell style={{ height: 53, textAlign: "center", fontSize: 20  }} colSpan={6} >Il n'y a pas des fournisseurs</TableCell></TableRow>}
          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }} key='emptyRows'>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow key='pagination'>
            <TablePagination
              rowsPerPageOptions={[25, 50, 100]}
              labelRowsPerPage={translation.CustomPaginationActionsTable.tablePaginationRowsPerPage}
              colSpan={5}
              count={count}
              rowsPerPage={rowsPerPage}
              page={count <= 0 ? 0 : page}
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page',
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
      }
      <SimpleDialog
        open={isFormOpen}
        isDistributor={accountTabtype}
        onClose={handleClose}
      />
    </TableContainer>
    
  );
}



export default CustomPaginationActionsTable
