import { createSlice } from '@reduxjs/toolkit';

export type DialogState = {
    isOpen: boolean,
    order: any,
    accountType: string,
    isAccept: boolean
}

const initialState: DialogState = {
    isOpen: false,
    order: {},
    accountType: '',
    isAccept: false
}

export const dialogSlice = createSlice({
    name: "dialog",
    initialState,
    reducers: {
        handleOpen: (state, {payload}) => {
            state.isOpen = true
            state.order = payload
            state.accountType = payload.accountType
            if (payload.isAccept) {
                state.isAccept = true
            }
        },
        handleCloseDialog: (state) => {
            state.isOpen = false
            state.isAccept = false
            state.order = ''
        }
    }
})

export const { handleCloseDialog, handleOpen } = dialogSlice.actions

export default dialogSlice.reducer