
const data = [
    { 
        "year" : "2022",
        "months": [
        {
            "Month": "Avril",
            "commands": 0
        },
        {
            "Month": "Mars",
            "commands": 33
        },
        {
            "Month": "Février",
            "commands": 55
        },
        {
            "Month": "Janvier",
            "commands": 120
        }
    ]},
    {
        "year": "2021",
        "months": [
        {
            "Month": "Décembre",
            "commands": 252
        },
        {
            "Month": "Novembre",
            "commands": 74
        },
        {
            "Month": "Octobre",
            "commands": 29
        },
        {
            "Month": "Septembre",
            "commands": 25
        },
        {
            "Month": "Août",
            "commands": 39
        },
        {
            "Month": "Juillet",
            "commands": 35
        },
        {
            "Month": "Juin",
            "commands": 42
        },
        {
            "Month": "Mai",
            "commands": 37
        },
        {
            "Month": "Avril",
            "commands": 51
        },
        {
            "Month": "Mars",
            "commands": 91
        },
        {
            "Month": "Février",
            "commands": 125
        },
        {
            "Month": "Janvier",
            "commands": 271
        }
    ]},
    {
        "year": "2020",
        "months": [
        {
            "Month": "Décembre",
            "commands": 519
        },
        {
            "Month": "Novembre",
            "commands": 129
        },
        {
            "Month": "Octobre",
            "commands": 74
        },
        {
            "Month": "Septembre",
            "commands": 50
        },
        {
            "Month": "Août",
            "commands": 39
        },
        {
            "Month": "Juillet",
            "commands": 65
        },
        {
            "Month": "Juin",
            "commands": 78
        },
        {
            "Month": "Mai",
            "commands": 26
        },
        {
            "Month": "Avril",
            "commands": 145
        },
        {
            "Month": "Mars",
            "commands": 34
        },
        {
            "Month": "Février",
            "commands": 365
        },
        {
            "Month": "Janvier",
            "commands": 211
        }
    ]}
]


export default data