import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import style from './Dashboard.module.css';
import logo from '../../assets/images/logo_44x44.webp';
import SidebarList from '../../components/SidebarList/SidebarList';
import translation from '../../locales/fr-translation.json';
import { Outlet, NavLink } from "react-router-dom";
// Firebase
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
// MaterialUI
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LogoutIcon from '@mui/icons-material/Logout';
import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container';
import { RootState } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { setAdminAccount, setDistributorAccount, setDistributorName } from "../../redux/reducers/account";
import { setErrorSnackBar, setSuccessSnackBar, closeSnackBar } from "../../redux/reducers/snackbar";
import { handleOpen, handleCloseDialog } from '../../redux/reducers/dialog';
import { useCancelOrderAdminMutation, useGetMyDistributorQuery } from '../../redux/apiSlice';
import CancelDialog from '../../components/CancelDialog/CancelDialog';


const Dashboard: React.FC = () => {

    const auth = getAuth();
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const openDialog = useSelector((state: RootState) => state.dialog.isOpen);
    const handleDialogClose = () => dispatch(handleCloseDialog());
    const isAccept = useSelector((state: RootState) => state.dialog.isAccept);
    const [cancelOrder, resultCancelOrder] = useCancelOrderAdminMutation();
    const [isUserSignedIn, setIsUserSignedIn] = useState(false);
    const [showSidebar, setShowSidebar] = useState(true);
    const [isDistributor, setIsDistributor] = useState(false);

    const _distributorData = useGetMyDistributorQuery('', { skip: !isDistributor });
    if (_distributorData.isSuccess) {
      if (_distributorData.data.companyName == null) {
        dispatch(setDistributorName(""));
      } else {
        dispatch(setDistributorName(_distributorData.data.companyName));     
      }

    }


    if (resultCancelOrder.isSuccess) {
      dispatch(setSuccessSnackBar(translation.Commandes.canceledSuccessMessage));

  } else if (resultCancelOrder.isError) {
      const errorData = JSON.stringify(resultCancelOrder.error);
      const errorDataJson = JSON.parse(errorData);
      dispatch(setErrorSnackBar(errorDataJson.error ? errorDataJson.error : errorDataJson.data.message));
  }

    setInterval(() => {
      auth.currentUser?.getIdToken().then((idToken) => {
        localStorage.setItem('profile',idToken)
    })
    }, 60000);

    auth.currentUser?.getIdToken().then((idToken) => {
        // console.debug(idToken);
        // console.log('id token: ',idToken);
        localStorage.setItem('profile',idToken)
    })

    
    useEffect(() => { 
        onAuthStateChanged(auth, (user) => {
            _distributorData.refetch();
            if (user) {
              setIsUserSignedIn(true);
              auth.currentUser?.getIdTokenResult()
                .then((idTokenResult) => {
                    if (idTokenResult.claims.role === "admin") {
                        // Show admin UI.
                        setIsDistributor(false);
                        dispatch(setAdminAccount());
                      } else {
                        // Show regular user UI.
                        setIsDistributor(true);
                        dispatch(setDistributorAccount());
                      }
                }).catch((error) => {
                    
                })
            } else {
              // User is signed out
              navigate('/');
              setIsUserSignedIn(false);
            }
          });
        
     }, [auth, navigate, dispatch])

     const handleSignOut = () => {
        signOut(auth).then(() => {
            // Sign-out successful.
          }).catch((error) => {
           
          });
     }    

    if (isUserSignedIn) {
        return (
            <div className={style.dashboardMainContainer}>
              <AppBar position='fixed' className={ style.appBar }>
                <Toolbar className={ style.toolBar }>
                  <IconButton
                    size='large'
                    edge='start'
                    color='inherit'
                    aria-label='menu'
                    sx={{ mr: 2 }}
                    onClick={() => {setShowSidebar(!showSidebar)}}
                  >
                    <MenuIcon />
                  </IconButton>
                  <NavLink to="/dashboard/profile" className={style.profileIcon}>
                      <IconButton
                        size='large'
                        aria-label='profile'
                        aria-controls='menu=appbar'
                        color='inherit'
                      >
                        <AccountCircle />
                      </IconButton>
                  </NavLink>

                </Toolbar>
              </AppBar>
              <div className={style.mainContainer}>
                  {showSidebar ? 
                      <>
                      <Box className={style.sideBar}>
                          <div className={style.headerSideBar}>
                            <Container className={ style.logoContainer }>
                                <img src={ logo } alt="Logo Le Cadeau Français" className={ style.logoImage } />
                                <p className={ style.logoText }>{translation.Dashboard.companyName}</p>
                            </Container>
                            <Divider />
                            <SidebarList isDistributor={ isDistributor } />
                          </div>
                          <div>
                            <Divider />
                            <ListItemButton onClick={handleSignOut}>
                              <ListItemIcon>
                                <LogoutIcon />
                              </ListItemIcon>
                              <ListItemText primary={translation.Dashboard.logoutText}></ListItemText>
                            </ListItemButton>
                          </div>
                      </Box> 
                      <Box className={style.sideBar2}>
                          <div className={style.headerSideBar}>
                            <Container className={ style.logoContainer }>
                                <img src={ logo } alt="Logo Le Cadeau Français" className={ style.logoImage } />
                                <p className={ style.logoText }>{translation.Dashboard.companyName}</p>
                            </Container>
                            <Divider />
                            <SidebarList isDistributor={ isDistributor } />
                          </div>
                          <div>
                            <Divider />
                            <ListItemButton onClick={handleSignOut}>
                              <ListItemIcon>
                                <LogoutIcon />
                              </ListItemIcon>
                              <ListItemText primary={translation.Dashboard.logoutText}></ListItemText>
                            </ListItemButton>
                          </div>
                      </Box> 
                      </>
                  : null}
                  <div className={style.sharedContainer}>
                    <Outlet />
                  </div>
              </div>
              <div>
                {
                  openDialog ?
                  <CancelDialog /> :
                  <></>
                }
                
              </div>
        </div>
         );
    } else {
       return (
           <Box className={ style.box }>
             <CircularProgress />
           </Box>
         );
    }
    

}

export default Dashboard