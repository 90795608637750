import React, { useState } from 'react';
import style from "./AddAdministrateurForm.module.css";
import translation from "../../locales/fr-translation.json";

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Stack from '@mui/material/Stack';
import InputAdornment from '@mui/material/InputAdornment';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useDispatch } from "react-redux";
import { setFormClose } from '../../redux/reducers/formDisplay';

import validator from 'validator';

import { useAddAdminMutation } from '../../redux/apiSlice';
import { setErrorSnackBar, setSuccessSnackBar } from '../../redux/reducers/snackbar';
import LodingPage from '../LodingPage/LodingPage';


const AddAdministrateurForm: React.FC = () => {
  const [lastName, setLastName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [emailError, setEmailError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [firstNameError, setFirstNameError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const [addAdmin, { isLoading, isError, error, isSuccess }] = useAddAdminMutation();

  const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(event.target.value);
    setLastNameError(validator.isEmpty(event.target.value));
  }

  const handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFirstName(event.target.value);
    setFirstNameError(validator.isEmpty(event.target.value));
  }

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    setEmailError(!validator.isEmail(event.target.value));
  }

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
    setPasswordError(!validator.isLength(event.target.value, { min: 6 }));
  }

  const dispatch = useDispatch();

  const  handleSubmit = () => {

    const admin = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      password: password
    }

    setLastNameError(validator.isEmpty(lastName));
    setFirstNameError(validator.isEmpty(firstName));
    setEmailError(!validator.isEmail(email));
    setPasswordError(!validator.isLength(password, { min: 6 }));



    if (validator.isEmpty(lastName) || validator.isEmpty(firstName) || !validator.isEmail(email) || !validator.isLength(password, { min: 6 })) {

     } else {
      // Admin.add(admin).then(()=> console.log('admin added'));
      addAdmin(admin);
      if (isLoading) {
      } else if (isSuccess) {
        dispatch(setFormClose());
      } else if (isError) {
      }
     }

  }


  if (isLoading) {
  } else if (isSuccess) {
    dispatch(setSuccessSnackBar(translation.SuccessMessages.addAdmin));
    dispatch(setFormClose());
  } else if (isError) {
    const errorData = JSON.stringify(error);
    const errorDataJson = JSON.parse(errorData);
    dispatch(setErrorSnackBar(errorDataJson.data.message));
  }



  return (
    <div className={style.mainCointainer}>
      {
        isLoading ?
        <LodingPage />:
        <></>
      }
        <Stack direction="row" justifyContent="space-between" alignItems="start">
            <p className={style.mainTitle}>{translation.AddAdministrateurForm.title}</p>
            <IconButton aria-label="delete" onClick={() => {dispatch(setFormClose())}}>
              <CloseIcon />
            </IconButton>
        </Stack>
        <TextField id="outlined-basic" label={translation.AddAdministrateurForm.lastName} variant="outlined" className={style.textField} onChange={handleLastNameChange} error={lastNameError} helperText={lastNameError? translation.AddAdministrateurForm.lastNameError: ""} InputProps={lastNameError? {endAdornment: <InputAdornment position="start"><ErrorOutlineIcon color='error' /></InputAdornment>}: {}}/>
        <TextField id="outlined-basic" label={translation.AddAdministrateurForm.firstName} variant="outlined" className={style.textField} onChange={handleFirstNameChange} error={firstNameError} helperText={firstNameError? translation.AddAdministrateurForm.firstNameError: ""} InputProps={firstNameError? {endAdornment: <InputAdornment position="start"><ErrorOutlineIcon color='error' /></InputAdornment>}: {}}/>
        <TextField id="outlined-basic" label={translation.AddAdministrateurForm.email} variant="outlined" className={style.textField} onChange={handleEmailChange} error={emailError} helperText={emailError? translation.AddAdministrateurForm.emailError: ""} InputProps={emailError? {endAdornment: <InputAdornment position="start"><ErrorOutlineIcon color='error' /></InputAdornment>}: {}}/>
        <TextField id="outlined-basic" label={translation.AddAdministrateurForm.password} variant="outlined" className={style.textField} type='password' onChange={handlePasswordChange} error={passwordError} helperText={passwordError? translation.AddAdministrateurForm.passwordError: ""} InputProps={passwordError? {endAdornment: <InputAdornment position="start"><ErrorOutlineIcon color='error' /></InputAdornment>}: {}}/>
        <Button variant="contained" className={style.button} onClick={() => handleSubmit()}>{translation.AddAdministrateurForm.submitButton}</Button>
    </div>
  )
}

export default AddAdministrateurForm;