import { createSlice } from '@reduxjs/toolkit'
import type { RootState } from "../store";

export enum NavigationAccountType {
    Administrator,
    Distributor
}

export type NavigationAccountState = {
    accountType: NavigationAccountType
}

const initialState: NavigationAccountState ={
    accountType: NavigationAccountType.Distributor
}

export const navigationAccountSlice = createSlice({
    name: "account",
    initialState,
    reducers: {
        setAdminAccountTab: (state) => {
            state.accountType = NavigationAccountType.Administrator
        },
        setDistributorAccountTab: (state) => {
            state.accountType = NavigationAccountType.Distributor
        }
    }
})

export const {setAdminAccountTab, setDistributorAccountTab} = navigationAccountSlice.actions

export const selectAccount = (state: RootState) => state.account.accountType

export default navigationAccountSlice.reducer