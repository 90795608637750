import React, { useEffect, useState } from 'react';
import style from './Authentication.module.css';
import logo from '../../assets/images/logo.webp';
import { Link, Navigate , useNavigate } from "react-router-dom";
import translation from '../../locales/fr-translation.json';
// Material 
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CardActions from '@mui/material/CardActions';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
// Firebase
import '../../firebase/firebase-config';
import { getAuth, signInWithEmailAndPassword, setPersistence, browserLocalPersistence, onAuthStateChanged } from 'firebase/auth';


const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  

const LogIn: React.FC = () => {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isUserSignedIn, setIsUserSignedIn] = useState(true);
    const [emailError, setEmailError] = useState(false);
    const [emailErrorMessage, setEmailErrorMessage] = useState("");
    const [passwordError, setPasswordError] = useState(false);
    const [passwordErrorMessage, setPasswordMessage] = useState("");
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const authentication = getAuth();


    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
        return;
        }

        setOpen(false);
     };

    const handleAction = () => {
        setPersistence(authentication, browserLocalPersistence)
            .then(() => {
                return signInWithEmailAndPassword(authentication, email, password)
                    .then((res) => { 
                        navigate('/dashboard');
                    });
            })
            .catch((error) => {
                const errorCode = error.code;

                if (errorCode == "auth/user-not-found") {
                    setEmailError(true);
                    setEmailErrorMessage(translation.Login.nonExistanceEmailError);
                    setPasswordError(false);
                    setPasswordMessage("");
                } else if (errorCode == "auth/wrong-password") {
                    setPasswordError(true);
                    setPasswordMessage(translation.Login.wrongPasswordError);
                    setEmailError(false);
                    setEmailErrorMessage("");
                } else if ( errorCode == "auth/invalid-email" ) {
                    setEmailError(true);
                    setEmailErrorMessage(translation.Login.invalidEmail);
                    setPasswordError(false);
                    setPasswordMessage("");
                } else {
                    setEmailError(false);
                    setEmailErrorMessage("");
                    setPasswordError(false);
                    setPasswordMessage("");
                    handleClick();
                }
            })


    }

    useEffect(() => {
        onAuthStateChanged(authentication, (user) => {
            if (user) {
              setIsUserSignedIn(true);
              navigate('/dashboard');

            } else {
              // User is signed out
              setIsUserSignedIn(false);
            }
          });

        
    }, [])


    if (!isUserSignedIn){
        return (
            <div>
                <Card variant="outlined" className={ style.card } >
                    <CardHeader title={translation.Login.cardTitle} />
                    <CardContent>
                        <form className={ style.form }>
                            <TextField 
                                id="email" 
                                label={translation.Login.email} 
                                variant="outlined" 
                                className={ style.textField } 
                                type='email' 
                                onChange={(e) => setEmail(e.target.value)}
                                error={emailError}
                                helperText={emailErrorMessage}
                            />
                            <TextField
                                id="password" 
                                label={translation.Login.password} 
                                variant="outlined" 
                                className={ style.textField } 
                                type='password' 
                                error={passwordError}
                                helperText={passwordErrorMessage}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <Button
                            variant="contained" 
                            className={ style.button }
                            onClick={handleAction}
                            >{translation.Login.buttonText}</Button>
                            <CardActions>
                                <Link to="/resetpassword" >
                                {translation.Login.forgetPassword}
                                </Link>
                            </CardActions>
                        </form>
                    </CardContent>
                </Card>
                <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                    {translation.Login.otherErrors}
                    </Alert>
                </Snackbar>
            </div>
  )} else {
    return (
        <Box className={ style.box }>
          <CircularProgress />
        </Box>
      );
  }
}

export default LogIn