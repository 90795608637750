import React, { useState } from 'react';
import style from "./UpdatingAdministrateurForm.module.css";
import translation from "../../locales/fr-translation.json";

import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Stack from '@mui/material/Stack';
import InputAdornment from '@mui/material/InputAdornment';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import API from '../../api/api';
import { useDispatch, useSelector } from "react-redux";
import { setFormClose, setEditFormClose } from '../../redux/reducers/formDisplay';
import { AppDispatch, RootState } from "../../redux/store";
import { setErrorSnackBar, setSuccessSnackBar, closeSnackBar } from "../../redux/reducers/snackbar";

import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

import validator from 'validator';

import { useUpdateAdminMutation, useGetAdminByIdQuery  } from '../../redux/apiSlice';
import LodingPage from '../LodingPage/LodingPage';



const UpdatingAdministrateurForm: React.FC = () => {
  const [lastName, setLastName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState<string | null>(null);

  const [emailError, setEmailError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [firstNameError, setFirstNameError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const adminUid = useSelector((state: RootState) => state.form.userUid);

  const [updateAdmin, { isLoading, isError, error, isSuccess }] = useUpdateAdminMutation();

  const _adminData = useGetAdminByIdQuery(adminUid);

  const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(event.target.value);
    setLastNameError(validator.isEmpty(event.target.value));
  }

  const handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFirstName(event.target.value);
    setFirstNameError(validator.isEmpty(event.target.value));
  }

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    setEmailError(!validator.isEmail(event.target.value));
  }

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  }

  const dispatch = useDispatch();

  const  handleSubmit = () => {

    const admin = {
      firstName: firstName ? firstName : _adminData.data.firstName,
      lastName: lastName ? lastName : _adminData.data.lastName,
      email: email ? email : _adminData.data.email,
      password: password
    }

    // setLastNameError(validator.isEmpty(lastName));
    // setFirstNameError(validator.isEmpty(firstName));
    // setEmailError(!validator.isEmail(email));



    // validator.isEmpty(lastName) || validator.isEmpty(firstName) || !validator.isEmail(email) 
    if (false) {

     } else {

      updateAdmin({uid: adminUid,admin: admin});
      if (isLoading) {

      } else if (isSuccess) {
   
        dispatch(setEditFormClose());
      } else if (isError) {
 
      }
     }
  }


  if (isSuccess) {
    dispatch(setSuccessSnackBar(translation.SuccessMessages.updateAdmin));
    dispatch(setEditFormClose());
  } else if (isError) {
    const errorData = JSON.stringify(error);
    const errorDataJson = JSON.parse(errorData);
    dispatch(setErrorSnackBar(errorDataJson.data.message));
  }

  return (
    <div className={style.mainCointainer}>
      {_adminData.isLoading?
        <LodingPage />
        :
      <div className={style.formContainer}>
        <Stack direction="row" justifyContent="space-between" alignItems="start">
            <p className={style.mainTitle}>{translation.UpdateAdministrateurForm.title}</p>
            <IconButton aria-label="delete" onClick={() => {dispatch(setEditFormClose())}}>
              <CloseIcon />
            </IconButton>
        </Stack>
        <TextField id="outlined-basic" label={translation.UpdateAdministrateurForm.lastName} variant="outlined" defaultValue={_adminData.isSuccess ? _adminData.data.lastName : "Error"} className={style.textField} onChange={handleLastNameChange} error={lastNameError} helperText={lastNameError? translation.UpdateAdministrateurForm.lastNameError: ""} InputProps={lastNameError? {endAdornment: <InputAdornment position="start"><ErrorOutlineIcon color='error' /></InputAdornment>}: {}}/>
        <TextField id="outlined-basic" label={translation.UpdateAdministrateurForm.firstName} variant="outlined" defaultValue={_adminData.isSuccess ? _adminData.data.firstName : "Error"} className={style.textField} onChange={handleFirstNameChange} error={firstNameError} helperText={firstNameError? translation.UpdateAdministrateurForm.firstNameError: ""} InputProps={firstNameError? {endAdornment: <InputAdornment position="start"><ErrorOutlineIcon color='error' /></InputAdornment>}: {}}/>
        <TextField id="outlined-basic" label={translation.UpdateAdministrateurForm.email} variant="outlined" defaultValue={_adminData.isSuccess ? _adminData.data.email : "Error"} className={style.textField} onChange={handleEmailChange} error={emailError} helperText={emailError? translation.UpdateAdministrateurForm.emailError: ""} InputProps={emailError? {endAdornment: <InputAdornment position="start"><ErrorOutlineIcon color='error' /></InputAdornment>}: {}}/>
        <TextField id="outlined-basic" label={translation.UpdateAdministrateurForm.password} variant="outlined" className={style.textField} type='password' onChange={handlePasswordChange} error={passwordError} helperText={passwordError? translation.UpdateAdministrateurForm.passwordError: ""} InputProps={passwordError? {endAdornment: <InputAdornment position="start"><ErrorOutlineIcon color='error' /></InputAdornment>}: {}}/>
        <Button variant="contained" className={style.button} onClick={() => handleSubmit()}>{translation.UpdateAdministrateurForm.submitButton}</Button>
        </div>}
    </div>
  )
}

export default UpdatingAdministrateurForm;