import React, { useState } from 'react';
import style from "./AdminstrateurProfile.module.css";
import translation from "../../locales/fr-translation.json";
import { RootState } from '../../redux/store';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useDispatch, useSelector } from 'react-redux';
import { useGetMyAdminQuery, useUpdateMyAdminMutation } from '../../redux/apiSlice';
import { setErrorSnackBar, setSuccessSnackBar } from "../../redux/reducers/snackbar";
import LodingPage from '../LodingPage/LodingPage';
import validator from 'validator';



const AdminstrateurProfile: React.FC = () => {
  const dispatch = useDispatch();
  const [lastName, setLastName] = useState<string>();
  const [firstName, setFirstName] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [password, setPassword] = useState<string | null>(null);

  const _distributorData = useGetMyAdminQuery('');

  const [updateDistributor, resultUpdateDistributor] = useUpdateMyAdminMutation();

  const [lastNameError, setLastNameError] = useState(false);
  const [firstNameError, setFirstNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(event.target.value);
    setLastNameError(validator.isEmpty(event.target.value));
  }

  const handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFirstName(event.target.value);
    setFirstNameError(validator.isEmpty(event.target.value));
  }

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    setEmailError(!validator.isEmail(event.target.value));
  }

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  }

  const handleSubmit = () => {

    const distributor = {
      lastName: lastName ? lastName : _distributorData.data.lastName,
      firstName: firstName ? firstName : _distributorData.data.firstName,
      email: email ? email : _distributorData.data.email,
      password: password == "" ? null : password
    }

    updateDistributor({distributor: distributor});

  }

  if (lastNameError || firstNameError || passwordError || emailError ) {
  } else {
    if (resultUpdateDistributor.isSuccess) {
      dispatch(setSuccessSnackBar(translation.SuccessMessages.updateDistributor));
    } else if (resultUpdateDistributor.isError) {
      const errorData = JSON.stringify(resultUpdateDistributor.error);
      const errorDataJson = JSON.parse(errorData);
      dispatch(setErrorSnackBar(errorDataJson.data.message));
    }
  }


  return (
    <div className={style.mainContainer}>
      {
        resultUpdateDistributor.isLoading || _distributorData.isLoading ?
        <LodingPage /> :
        <></>
      }
      <Typography variant='h5' className={style.subtitle}>{translation.DistributorProfile.adminstrativeContact}</Typography>
      {_distributorData.isSuccess ? <div className={style.fieldsContainer}>
          <TextField id={translation.AddDistributorForm.LastName} label={translation.AddDistributorForm.LastName} onChange={handleLastNameChange} name="lastName" defaultValue={_distributorData.data.lastName} variant="outlined" className={style.textField} error={lastNameError} helperText={lastNameError? translation.UpdateAdministrateurForm.lastNameError: ""} InputProps={lastNameError? {endAdornment: <InputAdornment position="start"><ErrorOutlineIcon color='error' /></InputAdornment>}: {}}/>
          <TextField id={translation.AddDistributorForm.FirstName} label={translation.AddDistributorForm.FirstName} onChange={handleFirstNameChange} name="firstName" defaultValue={_distributorData.data.firstName} variant="outlined" className={style.textField} error={firstNameError} helperText={firstNameError? translation.UpdateAdministrateurForm.firstNameError: ""} InputProps={firstNameError? {endAdornment: <InputAdornment position="start"><ErrorOutlineIcon color='error' /></InputAdornment>}: {}}/>
          <TextField id={translation.AddDistributorForm.email} label={translation.AddDistributorForm.email} onChange={handleEmailChange} name="email" defaultValue={_distributorData.data.email} variant="outlined" className={style.textField} error={emailError} helperText={emailError? translation.UpdateAdministrateurForm.emailError: ""} InputProps={emailError? {endAdornment: <InputAdornment position="start"><ErrorOutlineIcon color='error' /></InputAdornment>}: {}}/>
          <TextField id={translation.AddDistributorForm.password} label={translation.AddDistributorForm.password} onChange={handlePasswordChange} type="password" name="password" variant="outlined" className={style.textField} error={passwordError} helperText={passwordError? translation.UpdateAdministrateurForm.passwordError: ""} InputProps={passwordError? {endAdornment: <InputAdornment position="start"><ErrorOutlineIcon color='error' /></InputAdornment>}: {}}/>
      </div> : <LodingPage />}
      <Button variant='contained' startIcon={<EditIcon />} className={style.submitButton} onClick={() => handleSubmit()}>{translation.DistributorProfile.submitButton}</Button>
    </div>
  )
}

export default AdminstrateurProfile