import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../store';


export type FormState = {
    isFormOpen: boolean,
    isEditFormOpen: boolean,
    userUid: string
}

const initialState: FormState = {
    isFormOpen: false,
    isEditFormOpen: false,
    userUid: '',
}

export const formSlice = createSlice({
    name: "form",
    initialState,
    reducers: {
        setFormOpen: (state) => {
            state.isFormOpen = true
        },
        setFormClose: (state) => {
            state.isFormOpen = false
        },
        setEditFormOpen: (state, payload) => {
            state.isEditFormOpen = true
            state.userUid = payload.payload
        },
        setEditFormClose: (state) => {
            state.isEditFormOpen = false
        }
    }
})



export const {setFormOpen, setFormClose, setEditFormOpen, setEditFormClose } = formSlice.actions

export const selectFormStatus = (state: RootState) => state.form.isFormOpen
export const selectEditFormStatus = (state: RootState) => state.form.isEditFormOpen

export default formSlice.reducer