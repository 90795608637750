import { configureStore } from "@reduxjs/toolkit";
import accountReducer from "./reducers/account";
import navigationAccountTabReducer from "./reducers/navigationTabAccount";
import snackBarReducer from "./reducers/snackbar";
import alertReducer from "./reducers/alert";
import formReducer from "./reducers/formDisplay";
import distributorUidReducer from "./reducers/distributorUid";
import notificationMonProfilReducer from "./reducers/notificationMonProfil";
import notificationReducer from "./reducers/notification";
import dialogReducer from "./reducers/dialog";
import fetchedOrdersReducer from "./reducers/fetchedOrders";
import { apiSlice } from "./apiSlice";
import dialog from "./reducers/dialog";



const store = configureStore({
    reducer: {
        account: accountReducer,
        navigationAccountTab: navigationAccountTabReducer,
        snackbar: snackBarReducer,
        alert: alertReducer,
        form: formReducer,
        distributorUid: distributorUidReducer,
        notificationMonProfil: notificationMonProfilReducer,
        notification: notificationReducer,
        dialog: dialogReducer,
        fetchedOrders: fetchedOrdersReducer,
        [apiSlice.reducerPath]: apiSlice.reducer
    },
    middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(apiSlice.middleware),
    devTools: process.env.NODE_ENV !== "production"
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store