import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../store';
import { apiSlice } from '../apiSlice';

export type NotificationState = {
    notification: boolean
}

const initialState: NotificationState = {
    notification: false
}

export const notificationMonProfilSlice = createSlice({
    name: "notificationMonProfil",
    initialState,
    reducers: {
        setNotification: (state, { payload }) => {
            state.notification = payload
        }
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            apiSlice.endpoints.getMyDistributor.matchFulfilled,
            (state, { payload }) => {
                state.notification = payload.notifications
            }
        )
    }
})

export const { setNotification } = notificationMonProfilSlice.actions

export default notificationMonProfilSlice.reducer