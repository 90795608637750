import React, { useEffect, useState } from 'react';
import { RootState } from '../../redux/store';
import { useSelector , useDispatch } from "react-redux";
import style from "./Commandes.module.css";
import translate from "../../locales/fr-translation.json";

import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import NotificationsIcon from '@mui/icons-material/Notifications';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import MailIcon from '@mui/icons-material/Mail';
import WarningIcon from '@mui/icons-material/Warning';
import Commande from '../../components/Commande/Commande';
import Stack from '@mui/material/Stack';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import TextField from '@mui/material/TextField';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import CircularProgress from '@mui/material/CircularProgress';
import MenuItem from '@mui/material/MenuItem';

import { useGetAllOrdersQuery, useGetAllDistributorsQuery } from '../../redux/apiSlice';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { setDistributorUid } from "../../redux/reducers/distributorUid";
import LodingPage from '../../components/LodingPage/LodingPage';
import { setErrorSnackBar } from "../../redux/reducers/snackbar";
import Download  from '../../api/actions/downloads'; 
import axios from 'axios';
import InfinitScroll from '../../components/InfiniteScroll/InfinitScroll';
import { setOrders, setMoreOrders, setPage } from '../../redux/reducers/fetchedOrders';


// Download.packingLabel(2600)
// Download.shippingLabel(190118979)

const { REACT_APP_BACK_URI } = process.env

export enum CommandStatusColor {
  Received = "primary",
  Accepted = "success",
  Shipped = "secondary",
  Canceled = "error"
}

export enum TabStatus {
  RECEIVED = "RECEIVED",
  ACCEPTED = "ACCEPTED",
  SHIPPED = "SHIPPED",
  DECLINED = "DECLINED",
  ALL = "ALL"
  
}

export enum CommandStatus {
  Received = "REÇUES",
  Accepted = "ACCÉPTÉE",
  Shipped = "EXPÉDIÉE",
  Canceled = "ANNULÉE"
}
 
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;   
}

interface Counter {ALL?: number  , RECEIVED?: number  , ACCEPTED?: number , DECLINED?: number , SHIPPED?: number }

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Commandes: React.FC = () => {
  const dispatch = useDispatch();
  const [downloadLoding, setDownloadLoding] = useState(true);
  const accounttype = useSelector((state: RootState) => state.account.accountType);
  const page = useSelector((state: RootState) => state.fetchedOrders.page);
  const fetchedOrders = useSelector((state: RootState) => state.fetchedOrders.orders);
  const [type, setType] = useState<TabStatus>(TabStatus.RECEIVED)
  const [uid, setUid] = useState<string>("AllDist");
  const [search, setSearch] = useState<string | null>(null);
  const [counter, setCounter] = useState<Counter>();
  const [count, setCount] = useState(0);
  const DistrbutorName = useSelector((state: RootState) => state.account.distributorName);




  let getCommands = async ( page?: number | null, type?: string | null, uid?: string | null, search?: string | null) => {
    try {
      setDownloadLoding(true);
      await axios({
        method: 'GET',
        url: `${REACT_APP_BACK_URI}/${accounttype ? "distributor" : "admin"}/orders${search ? "/search" : ''}`,
        headers: {
          'Access-Control-Allow-Origin': true,
          'Authorization': `Bearer ${localStorage.getItem('profile')}`
        },
        params: {
          page: page == -1 ? 0 : page,
          type: type != "ALL" ? type : null,
          uid: uid != "AllDist" ? uid : null,
          search: search ? search : null
        }
      })
        .then((response) => {
          if (response.data.counter) {
            setCounter(response.data.counter);
            dispatch(setOrders([...response.data.orders]))
            setCount(response.data.counter[type as string])
          } else {
            dispatch(setMoreOrders([...response.data.orders]))
          }
          setDownloadLoding(false);
        })
        .catch((error) => {
          if (error.response.status == 400) {

          } else {
            dispatch(setErrorSnackBar(error.response.data.message));
          }
          setDownloadLoding(false);
        })
    } catch (error) {

    }
  }


  useEffect(() => {
    getCommands(page, type, uid, search);
  }, [page, type, uid, search]);

  useEffect(() => {
    if (search === '') {
      dispatch(setPage(0))
    }
  }, [search]);


  const [value, setValue] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };


  const handleChangeDistributor = (event: SelectChangeEvent) => {
    setUid(event.target.value as string);
    dispatch(setPage(0));
  };

  const allDistributorsInfo = useGetAllDistributorsQuery('', { skip: accounttype ? true : false });
  

  return (
    <div className={style.outerContainer}>
        <div className={style.header} >
            <p className={style.title}>Commandes {accounttype ? ` : ${DistrbutorName}` : ''}</p>
            <Stack direction="row" spacing={3}>
                <TextField id="outlined-basic" label="Recherche" variant="outlined" onChange={(event) => setSearch(event.target.value)} onClick={() => {dispatch(setPage(0))}} />
                {accounttype ? <></> :
                  <Box sx={{ minWidth: 250 }}>
                    <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-standard-label">Fournisseurs</InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard-label"
                          value={uid}
                          defaultValue={"AllDist"}
                          onChange={handleChangeDistributor}
                          label="demo-simple-select-standard-label"
                        > 
                            <MenuItem value={"AllDist"}>Tous les fournisseurs</MenuItem>
                            {allDistributorsInfo.isSuccess && allDistributorsInfo.data != null ?
                              (allDistributorsInfo.data.length != 0 ? allDistributorsInfo.data.map((distributor : any) => {return <MenuItem value={distributor.fulfillment_id} key={distributor.fulfillment_id}>{distributor.full_name}</MenuItem>}): <MenuItem value={"AllDist"}>Il n'y a pas de fournisseurs</MenuItem>)
                             : <>{allDistributorsInfo.data == null ? <></> : <MenuItem value={"AllDist"}>Téléchargement de données</MenuItem>}</>}
                        </Select>
                    </FormControl>
                </Box>}
            </Stack>
        </div>
        
          <Box className={style.mainContainer}>
            { counter ?
              <Box>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" centered variant="fullWidth">
                          
                          <Tab key={translate.Commandes.received} icon={<InfoOutlinedIcon />} label={translate.Commandes.received + " (" + counter.RECEIVED  +")"} {...a11yProps(0)} onClick={() => { dispatch(setPage(0)); setType(TabStatus.RECEIVED) }}/>
                          <Tab key={translate.Commandes.accepted} icon={<CheckBoxIcon />} label={translate.Commandes.accepted + " (" + counter.ACCEPTED +")"} {...a11yProps(1)} onClick={() => { dispatch(setPage(0)); setType(TabStatus.ACCEPTED) }}/>
                          <Tab key={translate.Commandes.shipped} icon={<MailIcon />} label={translate.Commandes.shipped + " (" + counter.SHIPPED +")"} {...a11yProps(2)} onClick={() => { dispatch(setPage(0)); setType(TabStatus.SHIPPED) }} />
                          <Tab key={translate.Commandes.canceled} icon={<WarningIcon />} label={translate.Commandes.canceled + " (" + counter.DECLINED +")"} {...a11yProps(3)} onClick={() => { dispatch(setPage(0)); setType(TabStatus.DECLINED) }}/>
                          <Tab key={translate.Commandes.all} icon={<NotificationsIcon />} label={translate.Commandes.all + " (" + counter.ALL +")"} {...a11yProps(4)} onClick={() => { dispatch(setPage(0)); setType(TabStatus.ALL) }}/>
                    </Tabs>
              </Box>
              : <></>
              }
              <TabPanel value={value} index={0}>
                  <InfinitScroll count={count}/>
              </TabPanel>
              <TabPanel value={value} index={1}>
                  <InfinitScroll count={count} />
              </TabPanel>
              <TabPanel value={value} index={2}>
                  <InfinitScroll count={count} />
              </TabPanel>
              <TabPanel value={value} index={3}>
                  <InfinitScroll count={count} />
              </TabPanel>
              <TabPanel value={value} index={4}>
                  <InfinitScroll count={count} />
              </TabPanel>
              </Box>
    </div>
  )
}

export default Commandes