import React, { useState } from 'react';
import style from './SidebarList.module.css';

import translation from '../../locales/fr-translation.json';
// Material
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import ReceiptIcon from '@mui/icons-material/Receipt';
import Divider from '@mui/material/Divider';
import SettingsIcon from '@mui/icons-material/Settings';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PeopleIcon from '@mui/icons-material/People';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import { NavLink } from "react-router-dom";

interface Props {
    isDistributor: boolean;
}

const SidebarList: React.FC<Props> = ({ isDistributor }) => {
    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen(!open);
    };

  return (
    <List
      sx={{ width: '100%', maxWidth: 256, bgcolor: 'background.paper' }}
      component="nav"
      aria-labelledby="nested-list-subheader"
      className={style.maincontainer}
    >
      <NavLink to="/dashboard" className={({ isActive }) => (isActive ? style.navLinkActive : style.navLink)} end={true}>
            <ListItemButton className={style.buttonBackground}>
                <ListItemIcon className={style.buttonIconColor}>
                <ContentPasteIcon />
                </ListItemIcon>
                <ListItemText primary={translation.SidebarList.commands}/>
            </ListItemButton>
      </NavLink>
      <NavLink to="/dashboard/history" className={({ isActive }) => (isActive ? style.navLinkActive : style.navLink)}>
            <ListItemButton className={style.buttonBackground}>
                <ListItemIcon className={style.buttonIconColor}>
                <ReceiptIcon />
                </ListItemIcon>
                <ListItemText primary={translation.SidebarList.history} />
            </ListItemButton>
      </NavLink>
      <NavLink to="/dashboard/profile" className={({ isActive }) => (isActive ? style.navLinkActive : style.navLink)}>
            <ListItemButton className={style.buttonBackground}>
                <ListItemIcon className={style.buttonIconColor}>
                <AccountCircleIcon />
                </ListItemIcon>
                <ListItemText primary={translation.SidebarList.profile} />
            </ListItemButton> 
      </NavLink>
      <Divider />
      <div hidden={isDistributor}>
            <ListItemButton onClick={handleClick}>
                <ListItemIcon >
                <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary={translation.SidebarList.accounts} />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                <NavLink to="/dashboard/distributors" className={({ isActive }) => (isActive ? style.navLinkActive : style.navLink)}>
                <ListItemButton sx={{ pl: 4 }} className={style.buttonBackground}>
                    <ListItemIcon className={style.buttonIconColor}>
                    <ShoppingCartIcon />
                    </ListItemIcon>
                    <ListItemText primary={translation.SidebarList.distributors} />
                </ListItemButton>
                </NavLink>
                <NavLink to="/dashboard/administrators" className={({ isActive }) => (isActive ? style.navLinkActive : style.navLink)}>
                        <ListItemButton sx={{ pl: 4 }} className={style.buttonBackground}>
                            <ListItemIcon className={style.buttonIconColor}>
                            <PeopleIcon />
                            </ListItemIcon>
                            <ListItemText primary={translation.SidebarList.admins} />
                        </ListItemButton>
                </NavLink>
                </List>
            </Collapse>
      </div>
    </List>
  )
}

export default SidebarList