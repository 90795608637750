import { createSlice } from "@reduxjs/toolkit";
import { alertSlice } from "./alert";

export type FetchedOrdersState = {
    orders: Array<any>,
    page: number,
}

const initialState: FetchedOrdersState = {
    orders: [],
    page: 0
}

export const fetchedOrdersSlice = createSlice({
    name: "fetchedOrders",
    initialState,
    reducers: {
        setOrders: (state, { payload }) => {
            state.orders = payload
        },
        setPage: (state, { payload }) => {
            state.page = payload
        },
        setMoreOrders: (state, { payload }) => {
            state.orders = [...state.orders, ...payload]
        },
        setSuccess: (state) => {
            state.orders =[]
            state.page = -1
        }
    }
})

export const { setOrders, setMoreOrders, setPage, setSuccess } = fetchedOrdersSlice.actions

export default fetchedOrdersSlice.reducer