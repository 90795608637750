import React from 'react';
import style from "./LodingPage.module.css";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const LodingPage: React.FC = () => {
  return (
    <div className={style.lodingContainer}>
              <CircularProgress className={style.circularProgress} size={100}/>
    </div>
  )
}

export default LodingPage