import { createSlice } from '@reduxjs/toolkit'
import type { RootState } from "../store";

export enum AccountType {
    Administrator,
    Distributor
}

export type AccountState = {
    accountType: AccountType,
    distributorName: string
}

const initialState: AccountState ={
    accountType: AccountType.Distributor,
    distributorName: ''
}

export const accountSlice = createSlice({
    name: "account",
    initialState,
    reducers: {
        setAdminAccount: (state) => {
            state.accountType = AccountType.Administrator
        },
        setDistributorAccount: (state) => {
            state.accountType = AccountType.Distributor
        },
        setDistributorName: (state, { payload }) => {
            state.distributorName = payload
        }
    }
})

export const {setAdminAccount, setDistributorAccount, setDistributorName} = accountSlice.actions

export const selectAccount = (state: RootState) => state.account.accountType

export default accountSlice.reducer