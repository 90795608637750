import React, { useEffect, useState } from 'react'
import style from './Authentication.module.css';
import logo from '../../assets/images/logo.webp';
import translation from '../../locales/fr-translation.json';
// MaterialUI
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
// Firebase
import '../../firebase/firebase-config';
import { getAuth, verifyPasswordResetCode, confirmPasswordReset, signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate, useSearchParams } from 'react-router-dom';


const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const NewPassword: React.FC = () => {

  const [searchParams, setSearchParams] = useSearchParams();
  const mode = searchParams.get("mode");
  const actionCode: string = searchParams.get("oobCode") || "";
  const continueUrl = searchParams.get("continueUrl");
  const lang = searchParams.get("lang") || "en";
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  const auth = getAuth();

  useEffect(() => { 
    
    if (!mode) {
      navigate('/dashboard');
    }
    
 }, [])

  
  const handleClickSnackeBar = () => {
    setOpen(true);
  };

  const handleCloseSnackeBar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  
  verifyPasswordResetCode(auth, actionCode).then((email) => {
    setEmail(email);
  }).catch((error) => {
    handleClickSnackeBar();
  })

  const handleClick = () => {
    confirmPasswordReset(auth, actionCode, password).then((reso) => {
      setPasswordError(false);
      setPasswordErrorMessage("");
      signInWithEmailAndPassword(auth, email, password).then(() => {
        navigate('/dashboard');
      });
      
      
    }).catch((error) => {
      setPasswordError(true);
      setPasswordErrorMessage(translation.NewPassword.invalidPasswordError);
    });
    
  }

  return (
    <div>
        <Card variant="outlined" className={ style.card } >
          <CardHeader title={translation.NewPassword.cardTitle} />
          <CardContent>
              <form className={ style.form }>
                  <TextField
                   id="email" 
                   label={ email } 
                   variant="outlined" 
                   className={ style.textField } 
                   type='email' 
                   disabled
                   error={emailError}
                   helperText={emailErrorMessage} 
                  />
                  <TextField
                   id="password" 
                   label={translation.NewPassword.password} 
                   variant="outlined" 
                   className={ style.textField } 
                   type='password' 
                   onChange={(e) => setPassword(e.target.value)}
                   error={passwordError}
                   helperText={passwordErrorMessage}
                  />
                  <Button
                   variant="contained" 
                   className={ style.button }
                   onClick={handleClick}
                  >{translation.NewPassword.buttonText}</Button>
              </form>
          </CardContent>
        </Card>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleCloseSnackeBar}>
          <Alert onClose={handleCloseSnackeBar} severity="error" sx={{ width: '100%' }}>
            { translation.NewPassword.otherErrors }
          </Alert>
        </Snackbar>
    </div>
  )
}

export default NewPassword