import { createSlice } from '@reduxjs/toolkit';

export type DistributorUidState ={
    uid: string
}

const initialState: DistributorUidState = {
    uid: "AllDist"
}

export const distributorUidSlice = createSlice({
    name: "distributorUid",
    initialState,
    reducers: {
        setDistributorUid: (state, { payload }) => {
            state.uid = payload
        }
    }
})

export const { setDistributorUid } = distributorUidSlice.actions

export default distributorUidSlice.reducer