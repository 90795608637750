import React, { useState } from 'react';
import style from "./DistributorProfile.module.css";
import translation from "../../locales/fr-translation.json";
import { RootState } from '../../redux/store';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import TextField from '@mui/material/TextField';
import ProfileField from '../ProfileField/ProfileField';
import InputAdornment from '@mui/material/InputAdornment';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useDispatch, useSelector } from 'react-redux';
import { useGetMyDistributorQuery, useUpdateMyDistributorMutation } from '../../redux/apiSlice';
import { setErrorSnackBar, setSuccessSnackBar } from "../../redux/reducers/snackbar";
import LodingPage from '../LodingPage/LodingPage';
import validator from 'validator';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { setNotification } from "../../redux/reducers/notificationMonProfil";


const DistributorProfile: React.FC = () => {
  const dispatch = useDispatch();
  const [lastName, setLastName] = useState<string>();
  const [firstName, setFirstName] = useState<string>();
  const [phone, setPhone] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [password, setPassword] = useState<string | null>(null);
  const [siretNumber, setSiretNumberId] = useState<string>();
  const [companyName, setCompanyName] = useState<string>();
  const [taxRate, setTaxRate] = useState<number>();
  const [address, setAddress] = useState<string>();
  const [postalCode, setPostalCode] = useState<string>();
  const [city, setCity] = useState<string>();
  const notification = useSelector((state: RootState) => state.notificationMonProfil.notification)

  const _distributorData = useGetMyDistributorQuery('');

  const [updateDistributor, resultUpdateDistributor] = useUpdateMyDistributorMutation();

  const [lastNameError, setLastNameError] = useState(false);
  const [firstNameError, setFirstNameError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [siretNumberError, setSiretNumberError] = useState(false);
  const [companyNameError, setCompanyNameError] = useState(false);
  const [taxRateError, setTaxRateError] = useState(false);
  const [addressError, setAddressError] = useState(false);
  const [postalCodeError, setPostalCodeError] = useState(false);
  const [cityError, setCityError] = useState(false);

  const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(event.target.value);
    setLastNameError(validator.isEmpty(event.target.value));
  }

  const handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFirstName(event.target.value);
    setFirstNameError(validator.isEmpty(event.target.value));
  }

  const handlePhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhone(event.target.value as string);
    setPhoneError(!validator.isLength(event.target.value, { min: 8 }));
  }

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    setEmailError(!validator.isEmail(event.target.value));
  }

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  }

  const handleSiretNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSiretNumberId(event.target.value);
    setSiretNumberError(validator.isEmpty(event.target.value));
  }

  const handleCompanyNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCompanyName(event.target.value);
    setCompanyNameError(validator.isEmpty(event.target.value));
  }

  const handleTaxRateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTaxRate(event.target.value as unknown as number);
    setTaxRateError(validator.isEmpty(event.target.value));
  }

  const handleAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAddress(event.target.value);
    setAddressError(validator.isEmpty(event.target.value));
  }

  const handlePostalCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPostalCode(event.target.value);
    setPostalCodeError(validator.isEmpty(event.target.value));
  }

  const handleCityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCity(event.target.value);
    setCityError(validator.isEmpty(event.target.value));
  }


  const handleSubmit = () => {

    const distributor = {
      lastName: lastName ? lastName : _distributorData.data.lastName,
      firstName: firstName ? firstName : _distributorData.data.firstName,
      phoneNumber: phone ? phone : _distributorData.data.phoneNumber,
      email: email ? email : _distributorData.data.email,
      password: password == "" ? null : password,
      notifications: notification,
      siret: siretNumber ? siretNumber : _distributorData.data.siret,
      tva: taxRate ? taxRate : _distributorData.data.tva,
      postalCode: postalCode ? postalCode : _distributorData.data.postalCode,
      companyName: companyName ? companyName : _distributorData.data.companyName,
      city: city ? city : _distributorData.data.city,
      address: address ? address : _distributorData.data.address
    }

    updateDistributor({distributor: distributor});

  }

  if (lastNameError || firstNameError || passwordError || emailError || phoneError || siretNumberError || taxRateError || postalCodeError || companyNameError || cityError || addressError) {
  } else {
    if (resultUpdateDistributor.isSuccess) {
      dispatch(setSuccessSnackBar(translation.SuccessMessages.updateDistributor));
    } else if (resultUpdateDistributor.isError) {
      const errorData = JSON.stringify(resultUpdateDistributor.error);
      const errorDataJson = JSON.parse(errorData);
      dispatch(setErrorSnackBar(errorDataJson.data.message));
    }
  }


  return (
    <div className={style.mainContainer}>
      {
        resultUpdateDistributor.isLoading || _distributorData.isLoading ?
        <LodingPage /> :
        <></>
      }
      <Typography variant='h5' className={style.subtitle}>{translation.AddDistributorForm.companyInformationTitle}</Typography>
      {_distributorData.isSuccess ? <div className={style.fieldsContainer}>
          <TextField id={translation.AddDistributorForm.siretNumber} label={translation.AddDistributorForm.siretNumber} onChange={handleSiretNumberChange} name="siretNumber" defaultValue={_distributorData.data.siret} variant="outlined" className={style.textField} error={siretNumberError} helperText={siretNumberError? translation.UpdatingDistributorForm.siretNumberError: ""} InputProps={siretNumberError? {endAdornment: <InputAdornment position="start"><ErrorOutlineIcon color='error' /></InputAdornment>}: {}}/>
          <TextField id={translation.AddDistributorForm.companyName} label={translation.AddDistributorForm.companyName} onChange={handleCompanyNameChange} name="companyName" defaultValue={_distributorData.data.companyName} variant="outlined" className={style.textField} error={companyNameError} helperText={companyNameError? translation.UpdatingDistributorForm.companyNameError: ""} InputProps={companyNameError? {endAdornment: <InputAdornment position="start"><ErrorOutlineIcon color='error' /></InputAdornment>}: {}}/>
          <TextField id={translation.AddDistributorForm.taxRate} label={translation.AddDistributorForm.taxRate} onChange={handleTaxRateChange} name="taxRate" defaultValue={_distributorData.data.tva} variant="outlined" className={style.textField} type='number' error={taxRateError} helperText={taxRateError? translation.UpdatingDistributorForm.taxRateError: ""} InputProps={taxRateError? {endAdornment: <InputAdornment position="start"><ErrorOutlineIcon color='error' /></InputAdornment>}: {}}/>
          <TextField id={translation.AddDistributorForm.address} label={translation.AddDistributorForm.address} onChange={handleAddressChange} name="address" defaultValue={_distributorData.data.address} variant="outlined" className={style.textField} error={addressError} helperText={addressError? translation.UpdatingDistributorForm.addressError: ""} InputProps={addressError? {endAdornment: <InputAdornment position="start"><ErrorOutlineIcon color='error' /></InputAdornment>}: {}}/>
          <TextField id={translation.AddDistributorForm.postalCode} label={translation.AddDistributorForm.postalCode} onChange={handlePostalCodeChange} name="postalCode" defaultValue={_distributorData.data.postalCode} variant="outlined" className={style.textField} error={postalCodeError} helperText={postalCodeError? translation.UpdatingDistributorForm.postalCodeError: ""} InputProps={postalCodeError? {endAdornment: <InputAdornment position="start"><ErrorOutlineIcon color='error' /></InputAdornment>}: {}}/>
          <TextField id={translation.AddDistributorForm.city} label={translation.AddDistributorForm.city} onChange={handleCityChange} name="city" defaultValue={_distributorData.data.city} variant="outlined" className={style.textField} error={cityError} helperText={cityError? translation.UpdatingDistributorForm.cityError: ""} InputProps={cityError? {endAdornment: <InputAdornment position="start"><ErrorOutlineIcon color='error' /></InputAdornment>}: {}}/>
      </div> : <LodingPage />}  
      <Typography variant='h5' className={style.subtitle}>{translation.DistributorProfile.adminstrativeContact}</Typography>
      {_distributorData.isSuccess ? <div className={style.fieldsContainer}>
          <TextField id={translation.AddDistributorForm.LastName} label={translation.AddDistributorForm.LastName} onChange={handleLastNameChange} name="lastName" defaultValue={_distributorData.data.lastName} variant="outlined" className={style.textField} error={lastNameError} helperText={lastNameError? translation.UpdateAdministrateurForm.lastNameError: ""} InputProps={lastNameError? {endAdornment: <InputAdornment position="start"><ErrorOutlineIcon color='error' /></InputAdornment>}: {}}/>
          <TextField id={translation.AddDistributorForm.FirstName} label={translation.AddDistributorForm.FirstName} onChange={handleFirstNameChange} name="firstName" defaultValue={_distributorData.data.firstName} variant="outlined" className={style.textField} error={firstNameError} helperText={firstNameError? translation.UpdateAdministrateurForm.firstNameError: ""} InputProps={firstNameError? {endAdornment: <InputAdornment position="start"><ErrorOutlineIcon color='error' /></InputAdornment>}: {}}/>
          <TextField id={translation.AddDistributorForm.phoneNumber} label={translation.AddDistributorForm.phoneNumber} onChange={handlePhoneNumberChange} name="phone" type="number" defaultValue={_distributorData.data.phoneNumber} variant="outlined" className={style.textField} error={phoneError} helperText={phoneError? translation.UpdateAdministrateurForm.phoneNumberError: ""} InputProps={phoneError? {endAdornment: <InputAdornment position="start"><ErrorOutlineIcon color='error' /></InputAdornment>}: {}}/>
          <TextField id={translation.AddDistributorForm.email} label={translation.AddDistributorForm.email} onChange={handleEmailChange} name="email" defaultValue={_distributorData.data.email} variant="outlined" className={style.textField} error={emailError} helperText={emailError? translation.UpdateAdministrateurForm.emailError: ""} InputProps={emailError? {endAdornment: <InputAdornment position="start"><ErrorOutlineIcon color='error' /></InputAdornment>}: {}}/>
          <TextField id={translation.AddDistributorForm.password} label={translation.AddDistributorForm.password} onChange={handlePasswordChange} type="password" name="password" variant="outlined" className={style.textField} error={passwordError} helperText={passwordError? translation.UpdateAdministrateurForm.passwordError: ""} InputProps={passwordError? {endAdornment: <InputAdornment position="start"><ErrorOutlineIcon color='error' /></InputAdornment>}: {}}/>
      </div> : <LodingPage />}
      <FormGroup>
        <FormControlLabel control={<Switch checked={notification} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {dispatch(setNotification(event.target.checked))}} />} label="Activer les notifications" />
      </FormGroup>
      <Button variant='contained' startIcon={<EditIcon />} className={style.submitButton} onClick={() => handleSubmit()}>{translation.DistributorProfile.submitButton}</Button>
    </div>
  )
}

export default DistributorProfile