import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../store';

export enum Severity {
    ERROR = "error",
    WARNING = "warning",
    INFO = "info",
    SUCCESS = "success"
}

export type SnackbarState = {
    isOpen: boolean,
    severity: Severity,
    message: string
}

const initialState: SnackbarState = {
    isOpen: false,
    severity: Severity.INFO,
    message: ""
}

export const snackbarSlice = createSlice({
    name: "snackbar",
    initialState,
    reducers: {
        setErrorSnackBar: (state, payload) => {
            state.isOpen = true
            state.severity = Severity.ERROR
            state.message = payload.payload
        },
        setSuccessSnackBar: (state, payload) => {
            state.isOpen = true
            state.severity = Severity.SUCCESS
            state.message = payload.payload
        },
        closeSnackBar: (state) => {
            state.isOpen = false
        }
    }
})

export const { setErrorSnackBar, setSuccessSnackBar, closeSnackBar } = snackbarSlice.actions

export const selectSnackBarStatus = (state: RootState) => state.snackbar.isOpen

export default snackbarSlice.reducer