import React, { useState } from 'react';
import style from "./UpdatingDistributorForm.module.css";
import translation from "../../locales/fr-translation.json";

import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { setEditFormClose } from '../../redux/reducers/formDisplay';
import { AppDispatch, RootState } from "../../redux/store";
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

import { useGetShopifyFullfillmentsQuery, useGetDistributorByIdQuery, useUpdateDistributorMutation } from '../../redux/apiSlice';
import { setErrorSnackBar, setSuccessSnackBar, closeSnackBar } from "../../redux/reducers/snackbar";
import { setNotification } from "../../redux/reducers/notification";

import validator from 'validator';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import LodingPage from '../LodingPage/LodingPage';


const UpdatingDistributorForm: React.FC = () => {
  const dispatch = useDispatch();
  const [idShopify, setIdShopify] = useState<string>();
  const [siretNumber, setSiretNumberId] = useState<string>();
  const [companyName, setCompanyName] = useState<string>();
  const [taxRate, setTaxRate] = useState<number>();
  const [address, setAddress] = useState<string>();
  const [postalCode, setPostalCode] = useState<string>();
  const [city, setCity] = useState<string>();
  const [lastName, setLastName] = useState<string>();
  const [firstName, setFirstName] = useState<string>();
  const [phone, setPhone] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [password, setPassword] = useState<string | null>(null);
  // const [notification, setNotification] = useState(true);
  const notification = useSelector((state: RootState) => state.notification.notification);

  const [emplacementIdError, setEmplpacementIdError] = useState(false);
  const [siretNumberError, setSiretNumberError] = useState(false);
  const [companyNameError, setCompanyNameError] = useState(false);
  const [taxRateError, setTaxRateError] = useState(false);
  const [addressError, setAddressError] = useState(false);
  const [postalCodeError, setPostalCodeError] = useState(false);
  const [cityError, setCityError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [firstNameError, setFirstNameError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [notificationError, setNotificationError] = useState(false);

  const distributorUid = useSelector((state: RootState) => state.form.userUid);


  const [updateDistributor, resultUpdateDistributor] = useUpdateDistributorMutation();
  const _distributorData = useGetDistributorByIdQuery(distributorUid);
  // _distributorData.data.fulfillmentIdShopify
  const {data, isLoading, isError, error, isSuccess} = useGetShopifyFullfillmentsQuery(_distributorData.isSuccess ? _distributorData.data.fulfillmentIdShopify : skipToken);



  const handleChangeShopifyId = (event: SelectChangeEvent) => {
    setIdShopify(event.target.value as string);
  };


  
  const handleSubmit = () => {

    const distributor = {
      idShopify: idShopify ? idShopify : _distributorData.data.idShopify,
      siret: siretNumber ? siretNumber : _distributorData.data.siret,
      companyName: companyName ? companyName : _distributorData.data.companyName,
      tva: taxRate ? taxRate : _distributorData.data.tva,
      address: address ? address : _distributorData.data.address,
      postalCode: postalCode ? postalCode : _distributorData.data.postalCode,
      city: city ? city : _distributorData.data.city,
      lastName: lastName ? lastName : _distributorData.data.lastName,
      firstName: firstName ? firstName : _distributorData.data.firstName,
      phoneNumber: phone ? phone : _distributorData.data.phoneNumber,
      email: email ? email : _distributorData.data.email,
      password: password == "" ? null : password,
      notifications: notification 
    }


    updateDistributor({uid: distributorUid, distributor: distributor});


  }


  if (resultUpdateDistributor.isSuccess) {
    dispatch(setSuccessSnackBar(translation.SuccessMessages.updateDistributor));
    dispatch(setEditFormClose());
  } else if (resultUpdateDistributor.isError) {
    const errorData = JSON.stringify(resultUpdateDistributor.error);
    const errorDataJson = JSON.parse(errorData);
    dispatch(setErrorSnackBar(errorDataJson.data.message));
  }



  return (
    <div className={style.mainCointainer}>
        {_distributorData.isLoading ?
                <LodingPage />
        :
        <div className={style.formContainer}>
                <Stack direction="row" justifyContent="space-between" alignItems="start">
                    <p className={style.mainTitle}>{translation.UpdatingDistributorForm.title}</p>
                    <IconButton aria-label="delete" onClick={() => {dispatch(setEditFormClose())}}>
                    <CloseIcon />
                    </IconButton>
                </Stack>
                <p className={style.secondaryTitle}>{translation.AddDistributorForm.shopifyIdendityTitle}</p>
                {/* <TextField id="outlined-basic" label={translation.AddDistributorForm.shopifyIdendityPlaceholder} variant="outlined" className={style.textField} name="emplacementId" onChange={(event) => {setEmplacementId(event.target.value)}}/> */}
                <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                    <InputLabel id="ShopifyIdLabel">{translation.AddDistributorForm.shopifyIdendityPlaceholder}</InputLabel>
                    <Select
                    labelId="demo-simple-select-label"
                    id={translation.AddDistributorForm.shopifyIdendityPlaceholder}
                    value={idShopify}
                    defaultValue={_distributorData.data.fulfillmentIdShopify}
                    label={translation.AddDistributorForm.shopifyIdendityPlaceholder}
                    onChange={handleChangeShopifyId}
                    >
                    {isSuccess ? data.map((client: any) => {
                        return <MenuItem value={client.id} id={client.id}>{client.name? client.name : client.current_name}</MenuItem>
                    }) : <MenuItem value='' id="empty">Loading data...</MenuItem>}


                    </Select>
                </FormControl>
                </Box>
                <Divider className={style.divider}/>
                <p className={style.secondaryTitle}>{translation.AddDistributorForm.companyInformationTitle}</p>
                <TextField id={translation.AddDistributorForm.siretNumber} label={translation.AddDistributorForm.siretNumber} name="siretNumber" defaultValue={_distributorData.data.siret} variant="outlined" className={style.textField} onChange={(event) => {setSiretNumberId(event.target.value)}}/>
                <TextField id={translation.AddDistributorForm.companyName} label={translation.AddDistributorForm.companyName} name="companyName" defaultValue={_distributorData.data.companyName} variant="outlined" className={style.textField} onChange={(event) => {setCompanyName(event.target.value)}}/>
                <TextField id={translation.AddDistributorForm.taxRate} label={translation.AddDistributorForm.taxRate} name="taxRate" defaultValue={_distributorData.data.tva} variant="outlined" className={style.textField} type='number' onChange={(event) => {setTaxRate(event.target.value as unknown as number)}}/>
                <TextField id={translation.AddDistributorForm.address} label={translation.AddDistributorForm.address} name="address" defaultValue={_distributorData.data.address} variant="outlined" className={style.textField} onChange={(event) => {setAddress(event.target.value)}}/>
                <TextField id={translation.AddDistributorForm.postalCode} label={translation.AddDistributorForm.postalCode} name="postalCode" defaultValue={_distributorData.data.postalCode} variant="outlined" className={style.textField} onChange={(event) => {setPostalCode(event.target.value)}}/>
                <TextField id={translation.AddDistributorForm.city} label={translation.AddDistributorForm.city} name="city" defaultValue={_distributorData.data.city} variant="outlined" className={style.textField} onChange={(event) => {setCity(event.target.value)}}/>
                <Divider className={style.divider}/>
                <p className={style.secondaryTitle}>{translation.AddDistributorForm.adminstrativeContactTitle}</p>
                <TextField id={translation.AddDistributorForm.LastName} label={translation.AddDistributorForm.LastName} name="lastName" defaultValue={_distributorData.data.lastName} variant="outlined" className={style.textField} onChange={(event) => {setLastName(event.target.value)}}/>
                <TextField id={translation.AddDistributorForm.FirstName} label={translation.AddDistributorForm.FirstName} name="firstName" defaultValue={_distributorData.data.firstName} variant="outlined" className={style.textField} onChange={(event) => {setFirstName(event.target.value)}}/>
                <TextField id={translation.AddDistributorForm.phoneNumber} label={translation.AddDistributorForm.phoneNumber} name="phone" defaultValue={_distributorData.data.phoneNumber} variant="outlined" className={style.textField} onChange={(event) => {setPhone(event.target.value)}}/>
                <TextField id={translation.AddDistributorForm.email} label={translation.AddDistributorForm.email} name="email" defaultValue={_distributorData.data.email} variant="outlined" className={style.textField} onChange={(event) => {setEmail(event.target.value)}}/>
                <TextField id={translation.AddDistributorForm.password} label={translation.AddDistributorForm.password} type="password" name="password" variant="outlined" className={style.textField} onChange={(event) => {setPassword(event.target.value)}}/>
                <Divider className={style.divider}/>
                <p className={style.secondaryTitle}>{translation.AddDistributorForm.dailyNotificationTitle}</p>
                <FormGroup>
                    <FormControlLabel control={<Switch checked={notification} onChange={(event) => {dispatch(setNotification(event.target.checked!))}}/>} label={translation.AddDistributorForm.dailyNotidicationSwitch}/>
                </FormGroup>
                <Button variant="contained" className={style.button} onClick={() => handleSubmit()}>{translation.UpdatingDistributorForm.submitButton}</Button>
        </div>}
    </div>
  )
}

export default UpdatingDistributorForm
