import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ResetPassword from './routes/Authentication/ResetPassword';
import NewPassword from './routes/Authentication/NewPassword';
import Dashboard from './routes/Dashboard/Dashboard';
import LogIn from './routes/Authentication/LogIn';
import SharedLogoImage from './routes/Authentication/SharedLogoImage';
import Commandes from './routes/Dashboard/Commandes';
import Historique from './routes/Dashboard/Historique';
import Fournisseurs from './routes/Dashboard/Fournisseurs';
import Administrateurs from './routes/Dashboard/Administrateurs';
import store  from './redux/store';
import { Provider } from "react-redux";
import MonProfil from './routes/Dashboard/MonProfil';
import PageNotFound from './routes/Dashboard/PageNotFound';
import SnackBar from './components/SnackBar/SnackBar';



const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // <React.StrictMode>
      <Provider store={store}>
          <SnackBar />
          <BrowserRouter>
            <Routes>
              <Route path="/" element={ <SharedLogoImage /> } >
                  <Route index element={ <LogIn /> } />
                  <Route path='/resetpassword' element={ <ResetPassword /> } />
                  <Route path='/newpassword' element= { <NewPassword /> } />
              </Route>
              <Route path='/dashboard' element={ <Dashboard /> } >
                  <Route index element={ <Commandes /> } />
                  <Route path="/dashboard/history" element={ <Historique /> } />
                  <Route path="/dashboard/distributors" element={ <Fournisseurs /> } /> 
                  <Route path='/dashboard/administrators' element={ <Administrateurs /> } />
                  <Route path="/dashboard/profile" element={ <MonProfil /> } />
              </Route>
              <Route path='*' element={ <PageNotFound /> } />
            </Routes>
          </BrowserRouter>
      </Provider>
  // </React.StrictMode>
);
