import React, { useEffect, useState } from 'react';
import CustomPaginationActionsTable from '../../components/CustomPaginationActionsTableDistributor/CustomPaginationActionsTableDistributor';
import style from "./Fournissseurs.module.css";
import translation from "../../locales/fr-translation.json";

import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';

import { RootState } from "../../redux/store";
import { useSelector, useDispatch } from "react-redux";
import { setDistributorAccountTab } from "../../redux/reducers/navigationTabAccount";
import { setFormClose, setFormOpen } from '../../redux/reducers/formDisplay';

import { useDeleteDistributorMutation } from '../../redux/apiSlice';
import { setErrorSnackBar, setSuccessSnackBar } from '../../redux/reducers/snackbar';
import LodingPage from '../../components/LodingPage/LodingPage';

const Fournisseurs: React.FC = () => {

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setDistributorAccountTab());
  }, [dispatch])
  

  // const accountTabtype = useSelector((state: RootState) => state.navigationAccountTab.accountType)
  const [open, setOpen] = useState(false);
  const isFormOpen = useSelector((state: RootState) => state.form.isFormOpen);

  const [deleteDistributor, result] = useDeleteDistributorMutation();

  if (result.isError) {
    const errorData = JSON.stringify(result.error);
    const errorDataJson = JSON.parse(errorData);
    console.log(errorDataJson)
    dispatch(setErrorSnackBar(errorDataJson.data.message));
  } else if (result.isSuccess) {
    dispatch(setSuccessSnackBar(translation.SuccessMessages.deleteDistributor));
  } else if (result.isLoading) {
  } 


  
  return (
    <div className={style.mainContainer}>
      {
        result.isLoading ?
        <LodingPage /> :
        <></>
      }
      <div className={style.headerContainer}>
          <p className={style.title}>{translation.Fournisseurs.title}</p>
          <Button variant='contained' startIcon={<AddIcon />} className={style.button} onClick={() => isFormOpen? dispatch(setFormClose()) : dispatch(setFormOpen())}>
            {translation.Fournisseurs.buttonText}
          </Button>
      </div>
      <CustomPaginationActionsTable open={open} setOpen={setOpen} isAdmin={false} deleteUser={deleteDistributor} />
    </div>
  )
}

export default Fournisseurs