import React, { useState } from 'react';
import style from "./addDistributorForm.module.css";
import translation from "../../locales/fr-translation.json";
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from "react-redux";
import { setFormClose } from '../../redux/reducers/formDisplay';
import { setAlertOpen, hideAlert } from '../../redux/reducers/alert';
import { RootState } from "../../redux/store";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useAddDistributorMutation, useGetAddShopifyFullfillmentsQuery } from '../../redux/apiSlice';
import { setErrorSnackBar, setSuccessSnackBar, closeSnackBar } from "../../redux/reducers/snackbar";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import validator from 'validator';
import LodingPage from '../LodingPage/LodingPage';
import InputAdornment from '@mui/material/InputAdornment';



const AddDistributorForm: React.FC = () => {
  const dispatch = useDispatch();
  const [idShopify, setIdShopify] = useState<string>('');
  const [siretNumber, setSiretNumberId] = useState<string>();
  const [companyName, setCompanyName] = useState<string>('');
  const [taxRate, setTaxRate] = useState<number>();
  const [address, setAddress] = useState<string>();
  const [postalCode, setPostalCode] = useState<string>();
  const [city, setCity] = useState<string>();
  const [lastName, setLastName] = useState<string>();
  const [firstName, setFirstName] = useState<string>();
  const [phone, setPhone] = useState<string>();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [notification, setNotification] = useState(true);

  const [emplacementIdError, setEmplpacementIdError] = useState(false);
  const [companyNameError, setCompanyNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const alertOpen = useSelector((state: RootState) => state.alert.isOpen);
  const alertmessage = useSelector((state: RootState) => state.alert.message);
  let open = false;
  let message = [];

  const {data, isLoading, isError, error, isSuccess} = useGetAddShopifyFullfillmentsQuery('');
  const [addDistributor, resultAddDistributor] = useAddDistributorMutation();

  const handleChangeShopifyId = (event: SelectChangeEvent) => {
    setIdShopify(event.target.value as string);
    setEmplpacementIdError(validator.isEmpty(event.target.value as string))
  };

  const handleChangeCompanyName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCompanyName(event.target.value);
    setCompanyNameError(validator.isEmpty(event.target.value))
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    setEmailError(!validator.isEmail(event.target.value));
  }

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
    setPasswordError(!validator.isLength(event.target.value, { min: 6 }));
  }

  
  const handleSubmit = () => {
    const distributor = {
      idShopify: idShopify,
      siret: siretNumber,
      companyName: companyName,
      tva: taxRate,
      address: address,
      postalCode: postalCode,
      city: city,
      lastName: lastName,
      firstName: firstName,
      phoneNumber: phone,
      email: email,
      password: password,
      notifications: notification
    }

    setEmailError(!validator.isEmail(email));
    setPasswordError(!validator.isLength(password, { min: 6 }));
    setCompanyNameError(validator.isEmpty(companyName));
    setEmplpacementIdError(validator.isEmpty(idShopify));

    if (!emailError || !passwordError || !companyNameError || !emplacementIdError) {
      addDistributor(distributor);
    }

    

  }


  if (resultAddDistributor.isSuccess) {
    dispatch(setSuccessSnackBar(translation.SuccessMessages.addDistributor));
    dispatch(setFormClose());
  } else if (resultAddDistributor.isError) {
    const errorData = JSON.stringify(resultAddDistributor.error);
    const errorDataJson = JSON.parse(errorData);
    if (errorDataJson.status == 400) {
      //
      // dispatch(setAlertOpen(errorDataJson.data.message));
      open = true;
      message = errorDataJson.data.message
    } else if (errorDataJson.status != 400) {
      dispatch(setErrorSnackBar(errorDataJson.data.message));
    }
  }

  return (
    <div className={style.mainCointainer}>
      {
        isLoading ?
        <LodingPage /> :
        <></>
      }
        <Stack direction="row" justifyContent="space-between" alignItems="start">
            <p className={style.mainTitle}>{translation.AddDistributorForm.title}</p>
            <IconButton aria-label="delete" onClick={() => {dispatch(setFormClose())}}>
              <CloseIcon />
            </IconButton>
        </Stack>
        <p className={style.secondaryTitle}>{translation.AddDistributorForm.shopifyIdendityTitle}</p>
        {/* <TextField id="outlined-basic" label={translation.AddDistributorForm.shopifyIdendityPlaceholder} variant="outlined" className={style.textField} name="emplacementId" onChange={(event) => {setEmplacementId(event.target.value)}}/> */}
        <Box sx={{ minWidth: 120 }}>
          <FormControl fullWidth>
            <InputLabel id="ShopifyIdLabel">{translation.AddDistributorForm.shopifyIdendityPlaceholder}</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id={translation.AddDistributorForm.shopifyIdendityPlaceholder}
              value={idShopify}
              label={translation.AddDistributorForm.shopifyIdendityPlaceholder}
              onChange={handleChangeShopifyId}
              error={emplacementIdError}
              inputProps={emplacementIdError? {endAdornment: <InputAdornment position="start"><ErrorOutlineIcon color='error' /></InputAdornment>}: {}}
            >
              {isSuccess ? data.map((client: any) => {
                return <MenuItem value={client.id} id={client.id}>{client.name}</MenuItem>
              }) : <MenuItem value='' id="empty">Loading data...</MenuItem>}

            </Select>
          </FormControl>
        </Box>
        <Divider className={style.divider}/>
        <p className={style.secondaryTitle}>{translation.AddDistributorForm.companyInformationTitle}</p>
        <TextField id={translation.AddDistributorForm.siretNumber} label={translation.AddDistributorForm.siretNumber} name="siretNumber" variant="outlined" className={style.textField} onChange={(event) => {setSiretNumberId(event.target.value)}}/>
        <TextField id={translation.AddDistributorForm.companyName} label={translation.AddDistributorForm.companyName} name="companyName" variant="outlined" className={style.textField} onChange={handleChangeCompanyName} error={companyNameError} helperText={companyNameError? translation.AddAdministrateurForm.companyNameError: ""} InputProps={companyNameError? {endAdornment: <InputAdornment position="start"><ErrorOutlineIcon color='error' /></InputAdornment>}: {}}/>
        <TextField id={translation.AddDistributorForm.taxRate} label={translation.AddDistributorForm.taxRate} name="taxRate" variant="outlined" className={style.textField} type='number' onChange={(event) => {setTaxRate(event.target.value as unknown as number)}}/>
        <TextField id={translation.AddDistributorForm.address} label={translation.AddDistributorForm.address} name="address" variant="outlined" className={style.textField} onChange={(event) => {setAddress(event.target.value)}}/>
        <TextField id={translation.AddDistributorForm.postalCode} label={translation.AddDistributorForm.postalCode} name="postalCode" variant="outlined" className={style.textField} onChange={(event) => {setPostalCode(event.target.value)}}/>
        <TextField id={translation.AddDistributorForm.city} label={translation.AddDistributorForm.city} name="city" variant="outlined" className={style.textField} onChange={(event) => {setCity(event.target.value)}}/>
        <Divider className={style.divider}/>
        <p className={style.secondaryTitle}>{translation.AddDistributorForm.adminstrativeContactTitle}</p>
        <TextField id={translation.AddDistributorForm.LastName} label={translation.AddDistributorForm.LastName} name="lastName" variant="outlined" className={style.textField} onChange={(event) => {setLastName(event.target.value)}}/>
        <TextField id={translation.AddDistributorForm.FirstName} label={translation.AddDistributorForm.FirstName} name="firstName" variant="outlined" className={style.textField} onChange={(event) => {setFirstName(event.target.value)}}/>
        <TextField id={translation.AddDistributorForm.phoneNumber} label={translation.AddDistributorForm.phoneNumber} name="phone" variant="outlined" className={style.textField} onChange={(event) => {setPhone(event.target.value)}}/>
        <TextField id={translation.AddDistributorForm.email} label={translation.AddDistributorForm.email} name="email" variant="outlined" className={style.textField} onChange={handleEmailChange} error={emailError} helperText={emailError? translation.AddAdministrateurForm.emailError: ""} InputProps={emailError? {endAdornment: <InputAdornment position="start"><ErrorOutlineIcon color='error' /></InputAdornment>}: {}}/>
        <TextField id={translation.AddDistributorForm.password} label={translation.AddDistributorForm.password} type="password" name="password" variant="outlined" className={style.textField} onChange={handlePasswordChange} error={passwordError} helperText={passwordError? translation.AddAdministrateurForm.passwordError: ""} InputProps={passwordError? {endAdornment: <InputAdornment position="start"><ErrorOutlineIcon color='error' /></InputAdornment>}: {}}/>
        <Divider className={style.divider}/>
        <p className={style.secondaryTitle}>{translation.AddDistributorForm.dailyNotificationTitle}</p>
        <FormGroup>
            <FormControlLabel control={<Switch defaultChecked checked={notification} onChange={(event) => {setNotification(event.target.checked!)}}/>} label={translation.AddDistributorForm.dailyNotidicationSwitch}/>
        </FormGroup>
        <Alert severity="error" className={open ? '' :style.alerthidden}>
            <AlertTitle>Erreur</AlertTitle>
            Les erreurs suivantes sont survenues :
            <ul>
            {message.map((item: any) => <li>{item}</li> )}
            </ul>
        </Alert>
        <Button variant="contained" className={style.button} onClick={() => handleSubmit()}>{translation.AddDistributorForm.submitButton}</Button>
    </div>
  )
}

export default AddDistributorForm
