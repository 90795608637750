import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../store';


export type AlertState = {
    isOpen: boolean,
    message: any
}

const initialState: AlertState = {
    isOpen: false,
    message: []
}

export const alertSlice = createSlice({
    name: "alert",
    initialState,
    reducers: {
        setAlertOpen: (state, payload) => {
            state.isOpen = true
            state.message = payload.payload
        },
        hideAlert: (state) => {
            state.isOpen = false
        }
    }
})

export const { setAlertOpen, hideAlert } = alertSlice.actions

export const selectAlertStatus = (state: RootState) => state.alert.isOpen

export default alertSlice.reducer