import React, { useState } from 'react';
import style from './Authentication.module.css';
import logo from '../../assets/images/logo.webp';
import translation from '../../locales/fr-translation.json';
import { Link } from "react-router-dom";
// MaterialUI
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
// Firebase
import { getAuth, sendPasswordResetEmail } from "firebase/auth";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ResetPassword: React.FC = () => {
  const auth = getAuth();

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [open, setOpen] = useState(false);

  const handleClickSnackeBar = () => {
    setOpen(true);
  };

  const handleCloseSnackeBar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleClick = () => {
    sendPasswordResetEmail(auth, email)
    .then(() => {
      handleClickSnackeBar();
      setEmailError(false);
      setEmailErrorMessage("");
      setEmail("");
    })
    .catch((error) => {
      const errorCode = error.code;
      if (errorCode == "auth/user-not-found") {
        setEmailError(true);
        setEmailErrorMessage(translation.ResetPassword.userNotFoundError);
      } else if (errorCode == "auth/invalid-email") {
        setEmailError(true);
        setEmailErrorMessage(translation.ResetPassword.invalidEmailError);
      } else if (errorCode == "auth/missing-email") {
        setEmailError(true);
        setEmailErrorMessage(translation.ResetPassword.missingEmailError);
      } else {
        setEmailError(true);
        setEmailErrorMessage(translation.ResetPassword.otherErrors);
      }
    });
  }

  return (
    <div>
        <Card variant="outlined" className={ style.card } >
          <CardHeader title={translation.ResetPassword.cardTitle} />
          <CardContent>
              <form className={ style.form }>
                  <TextField
                   id="email"
                   label={translation.ResetPassword.email} 
                   variant="outlined" 
                   className={ style.textField } 
                   type='email' 
                   onChange={(e) => setEmail(e.target.value)}
                   error={emailError}
                   helperText={emailErrorMessage}
                   value={email}
                   />
                  <Button
                   variant="contained" 
                   className={ style.button } 
                   onClick={handleClick}
                   > {translation.ResetPassword.buttonText} </Button>
              </form>
          </CardContent>
        </Card>
        <Snackbar open={open} autoHideDuration={3000} onClose={handleCloseSnackeBar}>
          <Alert onClose={handleCloseSnackeBar} severity="success" sx={{ width: '100%' }}>
            {translation.ResetPassword.emailSent}
          </Alert>
        </Snackbar>
    </div>
  )
}

export default ResetPassword