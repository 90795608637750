import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { RootState } from '../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { closeSnackBar } from '../../redux/reducers/snackbar';


const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  
const SnackBar: React.FC = () => {
    const dispatch = useDispatch();
    const open = useSelector((state: RootState) => state.snackbar.isOpen);
    const snackbarMessage = useSelector((state: RootState) => state.snackbar.message);
    const snackbarType = useSelector((state: RootState) => state.snackbar.severity);

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
          return;
        }
    
        dispatch(closeSnackBar());
      };

  return (
    <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={snackbarType} sx={{ width: '100%' }}>
        {snackbarMessage}
        </Alert>
    </Snackbar>
  )
}

export default SnackBar