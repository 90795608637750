import React from 'react';
import style from "./MonProfil.module.css";
import translation from "../../locales/fr-translation.json";

import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";
import DistributorProfile from '../../components/DistributorProfile/DistributorProfile';
import AdminstrateurProfile from '../../components/AdministrateurProfile/AdminstrateurProfile';

const MonProfil: React.FC = () => {
    const isDistributor = useSelector((state: RootState) => state.account.accountType);
    const accounttype = useSelector((state: RootState) => state.account.accountType);
    const DistrbutorName = useSelector((state: RootState) => state.account.distributorName);

  return (
    <div className={style.mainContainer}>
      <div className={style.headerContainer}>
          <p className={style.title}>{translation.MonProfil.title} {accounttype ? ` : ${DistrbutorName}` : ''}</p>
      </div>
        {isDistributor ? <DistributorProfile /> : <AdminstrateurProfile />}
    </div>
  )
}

export default MonProfil