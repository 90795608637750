import React from 'react'
import style from './Authentication.module.css';
import logo from '../../assets/images/logo.webp';
import { Link, Outlet } from 'react-router-dom';

const SharedLogoImage: React.FC = () => {
  return (
    <>  <div className={ style.container }>
            <img src={logo} alt='Logo le cadeau francais' className={ style.logoImage } />
            <Outlet />
        </div>
    </>
  )
}

export default SharedLogoImage